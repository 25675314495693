import { Adapter, IPagination } from "../../shared/types";

interface ConnectorItem {
  id: number;
  name: string;
}

interface ConnectorData {
  pagination: IPagination;
  items: ConnectorItem[];
}
export const connectorsAdapter: Adapter<ConnectorData> = (data) => {
  const {
    total_items: totalItems,
    total_pages: totalPages,
    current_page: currentPage,
    items_per_page: itemsPerPage,
  } = data;
  const pagination: IPagination = {
    totalItems,
    totalPages,
    currentPage,
    itemsPerPage,
  };

  const items: ConnectorItem[] = data.items.map((item: any) => {
    const {
      id = "",
      name = "",
    } = item || {};

    return {
      id,
      name,
    };
  });

  return { pagination, items };
};
