import * as Yup from "yup";

type InValidateDeleteError = {
  path: "message";
  message: string;
};

const validationTransferModalSchema = Yup.object().shape({
  message: Yup.string()
    .min(20, "El motivo debe tener al menos 20 caracteres")
    .required("Este campo es obligatorio"),
    file: Yup.mixed()
    .required("El archivo adjunto es obligatorio"),
});

export const handleValidationTransferSchema = async (formState: {
  message: string;
  file: File | null | string;
}) => {
  try {
    await validationTransferModalSchema.validate(formState, {
      abortEarly: false,
    });
    return { isValid: true, errors: {} as { message: string } };
  } catch (validationErrors: any) {
    const newErrors: { message: string } = {} as { message: string };
    validationErrors.inner.forEach((error: InValidateDeleteError) => {
      newErrors[error.path] = error.message;
    });
    return { isValid: false, errors: newErrors };
  }
};
