import globalFetch from "..";
import { Endpoints } from "../../shared/constants";
import { InAPI_Methods } from "../types";
import {
  InUserDataRequest,
  InUserRoleDataRequest,
  InUserRoleRoot,
  InUserRoot,
} from "../types/uers.types";

const { USERS, USER_ROLES } = Endpoints;
type FilterUser = "name" | "RUT";

export const getUserByIDService = async (
  search: string,
  id: FilterUser = "RUT"
): Promise<InUserDataRequest[]> => {
  try {
    const URL = `${USERS}?${id}=${search}`;
    const data: InUserRoot = await globalFetch(
      URL,
      InAPI_Methods.GET,
      undefined,
      false
    );
    return data.data;
  } catch (err) {
    throw err;
  }
};

export const getAllUserService = async (): Promise<InUserDataRequest[]> => {
  try {
    const URL = `${USERS}?no_sec_no_admin=true&limit=999`;
    const { data }: InUserRoot = await globalFetch(
      URL,
      InAPI_Methods.GET,
      undefined,
      false
    );
    return data;
  } catch (err) {
    throw err;
  }
};

export const getAllUserOwner = async (): Promise<InUserDataRequest[]> => {
  try {
    const URL = `${USERS}?only_owners=true`;
    const { data }: InUserRoot = await globalFetch(
      URL,
      InAPI_Methods.GET,
      undefined,
      false
    );
    return data;
  } catch (err) {
    throw err;
  }
};

export const getUserAvailableService = async (): Promise<
  InUserRoleDataRequest[]
> => {
  try {
    const URL = `${USER_ROLES}?extra_info=true&limit=999`;
    const { data }: InUserRoleRoot = await globalFetch(
      URL,
      InAPI_Methods.GET,
      undefined,
      false
    );
    return data;
  } catch (err) {
    throw err;
  }
};

export const putUserAvailableService = async (payload: {}): Promise<
  InUserRoleDataRequest[]
> => {
  try {
    const URL = `${USERS}`;
    const { message } = await globalFetch(
      URL,
      InAPI_Methods.PUT,
      payload,
      false
    );
    return message;
  } catch (err) {
    throw err;
  }
};
