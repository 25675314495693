import { Action } from "../../components";
import BrandConnectorReview from "./sections/brandReview";

export const headerLabels = [
  { key: "id", text: "Id" },
  { key: "name", text: "Marca" },
  { key: "action", text: "Acciones" },
];

interface ManyVoidFunctionsWithParams {
  [key: string]: (...args: any[]) => void;
}

export const tableActions = ({
  openModal,
  closeModal
}: ManyVoidFunctionsWithParams): Action[] => {
  return [
    {
      icon: "eye",
      hint: "Información Conector",
      handler: (revision) => {
        openModal(
          `Marca ${revision?.name}` ,
          <BrandConnectorReview
            data={revision}
            onClose={closeModal}
          />
        )},
    },
  ];
};

export const getDataTable = (data: any[]) => {
  return data.map((obj) => ({
    id: obj.id,
    name: obj.name,
  }));
};
