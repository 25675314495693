const Endpoints = {
  NOTIFICATIONS: "/messages",
  SEND_NOTIFICATIONS: "/send-email",
  LOCATIONS: "/locations",
  LOCATIONS_T6: "/update-te6-locations",
  FOLIO_REQUEST: "/approvals",
  FOLIO_INFO: "/locations",
  ENUM: "/enum",
  USERS: "/users",
  ROLES_DEFAULT: "/roles-to-show",
  USERS_RELATION: "/users-relation",
  CONNECTORS: "/connectors",
  EVSES: "/evses",
  MODEL_EVSE: "/evse-models",
  MODEL_BRAND: "/evse-brands",

  TARIFFS: "/tariffs",
  APPROVALS: "/approvals",
  RESOURCES: "/presigned-url",
  USER_ROLES: "/user-roles",
  PANEL_INFO: "/panel-info",
  FOLIO_NON_UPDATE: "/folio-nonupdated",
  HISTORICAL_CHANGES: "/historical-changes",
  CSV_FILE: "/download-change-info",
  LOCATIONS_USER: "/download-location-current-info",
  REPORT: "/report-problem"
};

const TableNames = {
  APPROVALS_STATUS: "approval_status",
  APPROVALS_TYPES: "approval_types",
  COMUNES: "communes",
  PARKING_TYPES: "parking_types",
  TARIFFS_DIMENSIONS: "tariff_dimensions",
  STATUS: "status",
  ROLES: "roles",
  FACILITIES_EVOLUTION: "facilities_evolution",
  STATUS_TYPE_APPROVAL: "status_approval",
  POWER_EVOLUTION: "power_evolution",
  STATUS_CONNECTORS: "connector_status",
  BRANDS: "evse_brands",
  PAYMENT_CAPABILITIES: "payment_capabilities",
  ACTIVATION_CAPABILITIES: "activation_capabilities",
};

const StatusApprovals = {
  APROBADO: "APROBADO",
  PENDIENTE: "PENDIENTE",
  EN_PROGRESO: "EN PROGRESO",
  RECHAZADO: "RECHAZADO",
  CAMBIOS: "SE SOLICITAN CAMBIOS",
};

const RolesIds = {
  SEC_SUERVISOR: 4,
};

const ROLES_NAME = {
  SEC: "SEC",
  SEC_ONLY_VIEW: "SEC_ONLY_VIEW",
  OPC: "OPC",
  CPO: "CPO",
  PSE: "PSE",
  OWNER: "OWNER",
  ADMIN: "ADMIN",
};

const TYPE_ACTIVITY = {
  TRASPASO_FOLIO: "TRASPASO DE FOLIO IRVE",
  DESASIGNACION_FOLIO: "DESASIGNACIÓN FOLIO",
  CONFIRM_TE6: "CONFIRMACIÓN DE DATOS DE TE-6",
  NEW_USER: "NUEVO USUARIO",
  ASSIGNMENT_PSE: "ASIGNACIÓN PSE",
  ASSIGNMENT_CPO: "ASIGNACIÓN OPC",
  ASSIGNMENT_OPC: "ASIGNACION OPC",
  AUTO_ASSIGNMENT: "AUTO-ASIGNACIÓN DE FOLIO IRVE",
};

const StatusMessages: Record<string, string> = {
  1: "Pendiente",
  2: "En progreso",
  3: "Completado",
};

export {
  Endpoints,
  StatusMessages,
  TableNames,
  StatusApprovals,
  RolesIds,
  ROLES_NAME,
  TYPE_ACTIVITY,
};
