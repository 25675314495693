import globalFetch from "..";
import { Endpoints } from "../../shared/constants";
import { InAPI_Methods } from "../types";

const { REPORT } = Endpoints;

export const getEmailReport = async (): Promise<any> => {
  try {
    const URL = `${REPORT}`;
    const data: any = await globalFetch(
      URL,
      InAPI_Methods.GET,
      undefined,
      false
    );
    return data.report_problem_email;
  } catch (err) {
    throw err;
  }
};

export const putEmailReport = async (payload: {}): Promise<any> => {
  try {
    const URL = `${REPORT}`;
    const { message } = await globalFetch(
      URL,
      InAPI_Methods.PATCH,
      payload,
      false
    );
    return message;
  } catch (err) {
    throw err;
  }
};

export const sendEmailReport = async (payload: {}): Promise<any> => {
  try {
    const URL = `${REPORT}`;
    const { message } = await globalFetch(
      URL,
      InAPI_Methods.POST,
      payload,
      false
    );
    return message;
  } catch (err) {
    throw err;
  }
};