import React, { useState, useCallback } from "react";
import { Grid, Segment, Modal, Form } from "semantic-ui-react";
import { useApprovalRejectionContext } from "../../context";
import {
  CommonButton,
  CommonInput,
  CommonInputFile
} from "../../../../components";

import { ModelConnectorsService } from "../../../../services/modelConnectorService"
import { toast } from "react-toastify";
import useFileUpload from "../../../../shared/hooks/useFileUpload"; // Importa el hook de subida de archivos
// import { Loader } from "semantic-ui-react"; // Importa el Loader de Semantic UI
import "semantic-ui-css/semantic.min.css";


const modelConnectorService = new ModelConnectorsService();

const FilterSection: React.FC = () => {
  const { setFilters } = useApprovalRejectionContext();
  const { handleUpload, handleDownload } = useFileUpload(); // Hook para subir archivos
  const [openModalcreateBrand, setOpenModalcreateBrand] = useState(false);
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [newBrand, setNewBrand] = useState("");
  const [file, setFile] = useState<File | null>(null); // Estado para almacenar el archivo seleccionado
  const [filtersFieldState, setFiltersFieldState] = useState({
    brand: "",
  });
  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);



  const handleFilterChange = useCallback(
    (key: any) => (_value: any) => {
      let value = "";
      if (!!_value) value = _value;

      setFiltersFieldState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersFieldState]
  );

  const handlecreateBrand = (value: string) => {
    setNewBrand(value);
  };

  // const handleSaveBrand = async () => {
  //   try {
  //     if(newBrand !== ""){
  //       const body = {
  //         evse_brand_name: newBrand
  //       }
  //       await modelConnectorService.createBrand(body);
  //       setOpenModalcreateBrand(false);
  //       toast.success(`Marca "${newBrand}" agregada.`);
  //     }
  //   } catch (error) {
  //     toast.error("Error en la búsqueda de marcas.");
  //   }
  // }

  const handleSaveBrand = async () => {
    try {
      if (newBrand !== "") {
        const body = {
          evse_brand_name: newBrand,
        };
        const response = await modelConnectorService.createBrand(body);
  
        // Verifica si la respuesta contiene un mensaje de éxito
        if (response && response.message) {
          toast.success(`Marca "${newBrand}" agregada.`); // Mensaje exitoso del backend
        } else {
          toast.error(`Marca "${newBrand}" ya existe.`); // Mensaje genérico
        }
        setOpenModalcreateBrand(false);
      } else {
        toast.error("Por favor, ingresa una marca válida.");
      }
    } catch (error: any) {
      // Captura el error y muestra el mensaje
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message); // Muestra el mensaje del backend
      } else {
        toast.error("Error en la búsqueda de marcas.");
      }
    }
  }

  const handleUploadFile = async () => {
    try {
      if (!file) {
        toast.error("Por favor, selecciona un archivo antes de guardar.");
        return;
      }
      setIsUploading(true);
      // Simula los valores de `userId` y `approvalId` según tu lógica
      const folder = "brand_models_info"; // Reemplaza con el valor correcto
      // const approvalId = "dummy-approval-id"; // Reemplaza con el valor correcto

      // await handleUpload(file, "brand_models", folder, approvalId);
      await handleUpload(file, "brand_models", folder);
      toast.success("Archivo subido exitosamente.");
      setOpenModalUpload(false); // Cierra el modal al terminar
      const body = {
        insert_from_excel: 'true'
      }
      await modelConnectorService.createBrand(body);
    } catch (error) {
      toast.error("Error al subir el archivo.");
    } finally {
      setIsUploading(false);
    }
  };

  const handleDownloadTemplate = async () => {
    try {
      setIsDownloading(true); // Inicia la indicación de carga
      const userId = "brand_models_info"; // Reemplaza con el ID correcto si es necesario
      const path = await handleDownload("brand_models", userId); // Lógica para obtener la URL
      if (path && path.length > 0) {
        const url = path[0];
        const link = document.createElement("a");
        link.href = url;
        link.download = "brand_models_info.xlsx"; // Cambia el nombre del archivo si es necesario
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.success("Plantilla descargada exitosamente.");
      } else {
        toast.error("No se encontró la plantilla para descargar.");
      }
    } catch (error) {
      toast.error("Error al intentar descargar la plantilla.");
    } finally {
      setIsDownloading(false); // Detiene la indicación de carga
    }
  };

  const handleSearch = () => setFilters(filtersFieldState);

  const contentCreateBrand = () => {
      return (
        <Modal.Content>
          <div>
            <CommonInput
              label="Marca"
              placeholder="Ingrese la marca"
              onChange={e => handlecreateBrand(e.target.value)}
              value={newBrand}
            />
          </div>
          <CommonButton
            className="mt-2 text-center"
            mode="primary"
            label="Guardar"
            onClick={() => handleSaveBrand()}
          />
        </Modal.Content>
      )
  }

  const contentUploadFile = () => (
    <Modal.Content>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <CommonInputFile
              key="XSLX"
              textButton="Adjuntar"
              onLoad={(file) => {
                console.log("Archivo cargado:", file);
                setFile(file);
              }} // Aquí puedes implementar la subida del archivo
              label="Seleccione .xslx . Si no tienes la plantilla, descárgala y adjúntala."
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form.Button
              content="Descargar plantilla"
              primary
              className="d-flex justify-center"
              onClick={handleDownloadTemplate}
              icon="download"
              loading={isDownloading}
              style={{ marginRight: "1.8rem" }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Modal.Content>
  );
  
  

  return (
    <Segment size="tiny">
      <Grid columns={5}>
        <Grid.Row verticalAlign="middle">
          <Grid.Column>
            <CommonInput
              label="Marca"
              placeholder="Marca"
              value={filtersFieldState.brand}
              onChange={(e) => handleFilterChange("brand")(e.target.value)}
            />
          </Grid.Column>
          <Grid.Column>
            <CommonButton
              label="Buscar"
              icon="search"
              size="tiny"
              onClick={() => handleSearch()}
            />
          </Grid.Column>
          <Grid.Column>
            <CommonButton
              label="Carga masiva de marcas"
              icon="add"
              size="tiny"
              onClick={() => setOpenModalUpload(true)}
            />
          </Grid.Column>
          <Grid.Column>
            <CommonButton
              label="Nueva marca individual"
              icon="add"
              size="tiny"
              onClick={() => setOpenModalcreateBrand(true)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Modal
        open={openModalcreateBrand}
        onClose={() => setOpenModalcreateBrand(false)}
        size="tiny"
        closeIcon={true}
        dimmer={'blurring'}
      >
        <Modal.Header>Crear nueva marca</Modal.Header>
        {contentCreateBrand()}
      </Modal>
      {/* Modal para subir archivo */}
      <Modal
        open={openModalUpload}
        onClose={() => setOpenModalUpload(false)}
        size="tiny"
        closeIcon={true}
        dimmer="blurring"
      >
        <Modal.Header>Adjuntar Archivo</Modal.Header>
        {contentUploadFile()}
          <Modal.Actions>
            <CommonButton
              label="Guardar"
              mode="primary"
              onClick={handleUploadFile} // Llama a la función de subida
              // onClick={() => console.log("Guardar archivo")}
              loading={isUploading} 
            />
          </Modal.Actions>
      </Modal>

    </Segment>
  );
};

export default FilterSection;
