import globalFetch from "..";
import { Endpoints } from "../../shared/constants";
import { InAPI_Methods } from "../types";
import {
  InLocationRequest,
  InLocationsConnector,
  InTariff,
  InModelEvsesRequestData,
  InLocationsChargers,
} from "../types/locations.types";

const {
  LOCATIONS,
  LOCATIONS_T6,
  APPROVALS,
  TARIFFS,
  CONNECTORS,
  EVSES,
  USER_ROLES,
  ENUM,
} = Endpoints;
interface InFilterLocations {
  commune?: string;
  region?: string;
  role_id?: number;
  user_id?: number;
  page?: number;
  limit?: number;
  location_id?: number;
  folio_irve?: string;
  name?: string;
  order_parameter?: string;
  order_rule?: string;
}

export const getLocationsService = async (
  filters?: InFilterLocations
): Promise<InLocationRequest> => {
  try {
    const params = Object.fromEntries(
      Object.entries(filters || {}).map(([key, value]) => [key, String(value)])
    );
    const queryParams = new URLSearchParams(params);
    const URL = `${LOCATIONS}?${queryParams}`;
    const data: InLocationRequest = await globalFetch(
      URL,
      InAPI_Methods.GET,
      undefined,
      false
    );
    return data;
  } catch (err) {
    throw err;
  }
};

export const UpdateEvsesService = async (
  payload: InLocationsChargers
): Promise<InLocationRequest> => {
  try {
    const URL = `${EVSES}`;
    const data = await globalFetch(URL, InAPI_Methods.PATCH, payload, true);
    console.log("Respuestaaaa", data);
    if (data && data?.statusCode) {
      if (![200, 201, 204].includes(data.statusCode))
        throw new Error("Ocurrio un error");
    }

    return data;
  } catch (err) {
    throw err;
  }
};

export const UpdateConnectorService = async (
  payload: InLocationsConnector
): Promise<InLocationRequest> => {
  try {
    const URL = `${CONNECTORS}`;
    const data = await globalFetch(URL, InAPI_Methods.PATCH, payload, true);

    if (data && data?.statusCode) {
      if (![200, 201, 204].includes(data.statusCode))
        throw new Error("Ocurrio un error");
    }

    return data;
  } catch (err) {
    throw err;
  }
};

export const createTariffService = async (
  payload: InTariff
): Promise<InLocationRequest> => {
  try {
    const URL = `${TARIFFS}`;
    const data = await globalFetch(URL, InAPI_Methods.POST, payload, true);

    if (data && data?.statusCode) {
      if (![200, 201, 204].includes(data.statusCode))
        throw new Error("Ocurrio un error");
    }
    return data;
  } catch (err) {
    throw err;
  }
};

export const UpdateTariffService = async (
  payload: InTariff
): Promise<InLocationRequest> => {
  try {
    const URL = `${TARIFFS}`;
    const data = await globalFetch(URL, InAPI_Methods.PATCH, payload, true);

    if (data && data?.statusCode) {
      if (![200, 201, 204].includes(data.statusCode))
        throw new Error("Ocurrio un error");
    }
    return data;
  } catch (err) {
    throw err;
  }
};

export const DeleteAssignPSE = async (
  userId: number,
  locationId: number,
  roleId = 3
): Promise<boolean> => {
  try {
    const URL = `${USER_ROLES}?user_id=${userId}&location_id=${locationId}&role_id=${roleId}`;
    await globalFetch(URL, InAPI_Methods.DELETE);
    return true;
  } catch (err) {
    throw err;
  }
};

export const approvalsService = async (
  approvalTypeId: number,
  userId: number,
  locationId: number,
  message: string,
  userIdTarget?: number,
  isConfirm = true
): Promise<string> => {
  try {
    const URL = `${APPROVALS}`;
    const getApprovalType =
      approvalTypeId === 7 ? 1 : approvalTypeId === 4 && !userIdTarget ? 1 : 2;

    let payload = {
      user_id: userId,
      approval_status_id: isConfirm ? getApprovalType : 1,
      approval_type_id: approvalTypeId,
      user_id_target: userIdTarget,
      location_id: locationId,
      message_request: message,
      fields_request: "{}",
    };

    const data: { statusCode: number; message: string } = await globalFetch(
      URL,
      InAPI_Methods.POST,
      payload
    );
    if (data && data?.statusCode) {
      if (![200, 201, 204].includes(data.statusCode)) {
        const errorMessage =
          data?.message && data.message.includes("Approval already exists")
            ? "Ya existe una solicitud en proceso"
            : "Ocurrio un error al intentar realizar la petición";

        throw new Error(errorMessage);
      }
      return data.message;
    }
    return data.message;
  } catch (err) {
    throw err;
  }
};

export const handleConfirmInfoService = async (
  userId: number,
  locationId: number,
  message: string,
  fields: any,
  type = 3
): Promise<boolean> => {
  try {
    const URL = `${APPROVALS}`;
    const data = await globalFetch(URL, InAPI_Methods.POST, {
      user_id: userId,
      approval_type_id: type,
      approval_status_id: 1,
      location_id: locationId,
      message_request: message,
      fields_request: fields,
    });
    if (data && data?.statusCode) {
      if (![200, 201, 204].includes(data.statusCode)) {
        const errorMessage =
          data?.message && data.message.includes("Approval already exists")
            ? "Ya existe una solicitud en proceso"
            : "Ocurrio un error al intentar realizar la petición";

        throw new Error(errorMessage);
      }
    }
    return true;
  } catch (err) {
    console.log("Entre al catch", err);
    throw err;
  }
};

export const handleUpdateT6LocationsService = async (
  payload: any
): Promise<boolean> => {
  try {
    const URL = `${LOCATIONS_T6}`;
    const data = await globalFetch(URL, InAPI_Methods.PATCH, payload);
    if (data && data?.statusCode) {
      if (![200, 201, 204].includes(data.statusCode))
        throw new Error("Ocurrio un errorb");
    }
    return true;
  } catch (err) {
    console.log("Entre al catch", err);
    throw err;
  }
};

export const getModelByBrandsIdService = async (brandId: number) => {
  try {
    const URL = `${ENUM}?table_name=evse_models&evse_brand_id=${brandId}`;
    const { data }: InModelEvsesRequestData = await globalFetch(
      URL,
      InAPI_Methods.GET
    );
    return data;
  } catch (error) {
    return [];
  }
};
