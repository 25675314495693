import React, { createContext, ReactNode, useContext } from "react";
import useBrandConnectorData from "./hooks/useBrandConnectorData";

const ApprovalRejectionContext = createContext<any>([]);

export const ApprovalRejectionProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {

  const information = useBrandConnectorData();

  return (
    <ApprovalRejectionContext.Provider
      value={{
        ...information,
      }}
    >
      {children}
    </ApprovalRejectionContext.Provider>
  );
};

export const useApprovalRejectionContext = () => useContext(ApprovalRejectionContext);
