import React from "react";
import { Action } from "../../components";
import ApprovalReview from "./sections/approveReview";
import RevisionAcceptanceCard from "./sections/acceptanceReview";
import TraspasoInfo from "./components/TraspasoInfo";
import {
  ROLES_NAME,
  StatusApprovals,
  TYPE_ACTIVITY,
} from "../../shared/constants";
import moment from "moment";
import { functionShared } from "../../shared/functions";
import ConfirmT6SEC from "./components/ConfirmT6SEC";
import MessageList from "./sections/MessageList";
import { runFormater } from "../../shared/utils/runService";

const { ASSIGNMENT_CPO, ASSIGNMENT_PSE, NEW_USER, AUTO_ASSIGNMENT } =
  TYPE_ACTIVITY;

export const headerLabels = [
  { key: "approvalId", text: "Id" },
  { key: "folio", text: "Folio" },
  { key: "installationDate", text: "Fecha" },
  { key: "address", text: "Dirección" },
  { key: "comuna", text: "Comuna" },
  { key: "region", text: "Región" },
  { key: "activity", text: "Operación" },
  { key: "status", text: "Estado" },
  { key: "chargersCount", text: "N° Cargadores" },
  { key: "Propietario", text: "Propietario" },
  { key: "action", text: "Acciones" },
];

interface ManyVoidFunctionsWithParams {
  [key: string]: (...args: any[]) => void;
}

export const tableActions = ({
  openModal,
  closeModal,
  changeStatusReview,
}: ManyVoidFunctionsWithParams): Action[] => {
  let isOwner = false;
  const actions = localStorage.getItem("session-storage");
  if (actions) {
    const parsedAction = JSON.parse(actions);
    if (parsedAction && parsedAction.state && parsedAction.state.profile) {
      isOwner = [
        ROLES_NAME.CPO,
        ROLES_NAME.OPC,
        ROLES_NAME.PSE,
        ROLES_NAME.OWNER,
      ].includes(parsedAction.state.profile?.currentRole?.nameRol);
    }
  }
  return [
    {
      icon: "eye",
      hint: "Información Solicitud",
      handler: (revision) =>
        openModal(
          "Revisión",
          <ApprovalReview
            data={revision}
            onClose={closeModal}
            onAction={changeStatusReview}
          />
        ),
    },
    {
      icon: "paperclip",
      hint: "Asumir Solicitud",
      handler: (revision) =>
        openModal(
          "Asumir revisión",
          <RevisionAcceptanceCard
            data={revision}
            onAction={changeStatusReview}
            onClose={closeModal}
          />
        ),
      hideIcon: (revision: any) =>
        !(revision.status === StatusApprovals.PENDIENTE) ||
        (isOwner &&
          ![ASSIGNMENT_CPO, ASSIGNMENT_PSE].includes(revision.activity)),
    },
    {
      icon: "comment",
      hint: "Ver notificaciones",
      handler: ({ approvalId, status, activity, user, location }) =>
        openModal(
          `Notificaciones`,
          <MessageList
            approvalId={approvalId}
            approvalState={status}
            closeModal={closeModal}
            approvalStatus={activity}
            user={user}
            location={location}
          />
        ),
    },
    {
      icon: "edit",
      hint: "Modificar información folio",
      hideIcon: (revision: any) => revision.activity === NEW_USER || isOwner,
      handler: (revision) =>
        openModal(
          `Folio ${revision.folio}`,
          <ConfirmT6SEC folioId={revision.folio} onClose={closeModal} />
        ),
    },
  ];
};

export const getDataTable = (data: any[]) => {
  return data.map((obj) => ({
    approvalId: obj.approvalId,
    folio:
      obj.approvalType === NEW_USER
        ? runFormater(obj.user?.rut)
        : obj.folioIRVE,
    installationDate: functionShared.formatTimeDifference(obj.updatedAtDetails),
    updatedAtDetails: moment(obj.updatedAtDetails).format(
      "DD MMMM YYYY - HH:mm:ss"
    ),
    address: obj.approvalType === NEW_USER ? obj.user?.address : obj.address,
    comuna: obj.approvalType === NEW_USER ? obj.user?.commune : obj.commune,
    region: obj.approvalType === NEW_USER ? obj.user?.region : obj.region,
    activity: obj.approvalType,
    status: obj.approvalStatus,
    chargersCount: obj.chargersCount,
    Propietario: obj.user?.name,
    opc: obj.opc,
    pse: obj.pse,
    action: obj.action,
    location: obj.location,
    user: obj.user,
    userTarget: obj.userTarget,
    sec: obj.sec,
    subject: obj.messageRequest,
    fieldsRequest:
      obj?.fieldsRequest && typeof obj.fieldsRequest === "string"
        ? JSON.parse(obj?.fieldsRequest)
        : obj?.fieldsRequest,
  }));
};

export const contentReviewData = (data: any) => {
  const columnLeft = [
    {
      label: "Folio",
      value: data.folio,
    },
    {
      label: "Fecha de Instalación",
      value: data.updatedAtDetails,
    },
    {
      label: "Dirección",
      value: `${data.address}, ${data.comuna}, ${data.region}`,
    },
    {
      label: "Actividad",
      value: <TraspasoInfo data={data} />,
    },
  ];

  const columnRight = [
    {
      label: "Estado",
      value: data.status,
    },
    {
      label: "Número de Cargadores",
      value: data.chargersCount,
    },
    {
      label: data.activity !== AUTO_ASSIGNMENT ? "Propietario" : "Solicitante",
      value: data.Propietario,
    },
    {
      label: "Motivo",
      value: data.subject,
    },
  ];

  return { columnLeft, columnRight };
};
