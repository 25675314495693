import React, { useEffect, useState } from "react";
import {
  Card,
  Input,
  TableBody,
  Table,
} from "semantic-ui-react";
import { ModelConnectorsService } from "../../../../services/modelConnectorService"
import { CommonButton } from "../../../../components";
import { toast } from "react-toastify";

interface Props {
  data: any;
  onClose: () => void;
}

const modelConnectorService = new ModelConnectorsService();

const BrandConnectorReview: React.FC<Props> = ({ data, onClose }) => {
  const [evses, setevses] = useState<any>([]);
  const [model, setModel] = useState<string>("");
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    const getEvses = async () => {
      try {
        const items = await modelConnectorService.getAllEvses();
        setevses(items);
      } catch (error) {
        toast.error("Ocurrio un error al intentar obtener los modelos");
      }
    };
    getEvses();
    // eslint-disable-next-line
  }, [data]);

  const getEvsesByBrand = () => {
    return (
      <Card.Content>
        <Table
            size="small"
            style={{ maxHeight: "260px", overflow: "hidden" }}
            definition
          >
            <TableBody className="table-responsability-body-content">
              <Table.Row>
                <Table.Cell style={{"width": "3.8rem"}}>ID</Table.Cell>
                <Table.Cell><strong>Modelo</strong></Table.Cell>
              </Table.Row>
              {evses.filter((x: any) => x.brand_name === data.name).map((content: any) => (
                <Table.Row>
                  <Table.Cell style={{"width": "3.8rem"}}>{content.id}</Table.Cell>
                  <Table.Cell>{content.model_name}</Table.Cell>
                </Table.Row>
              ))}
           </TableBody>
        </Table>
      </Card.Content>
    );
  };

  const handleSaveEvse = async () => {
    if (count === 0) {
      toast.info("No hay modelos nuevos para guardar.");
      onClose(); // Cierra la ventana si no hay modelos nuevos
      return;
    }
  
    toast.info("Guardando nuevos modelos...");
  
    for (let index = 0; index < count; index++) {
      const body = {
        brand_id: data.id,
        model_name: evses[evses.length - (1 + index)].model_name,
      };
  
      try {
        const response = await modelConnectorService.createEvse(body);
  
        // Verifica si la respuesta contiene un mensaje del backend
        if (response && response.message) {
          toast.success(response.message);
        } else {
          toast.error(`Marca "${body.model_name} ya existe.`);
        }
      } catch (error: any) {
        // Manejo de errores del backend
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(`Error: ${error.response.data.message}`);
        } else {
          toast.error(`No se logró crear el modelo "${body.model_name}".`);
        }
      }
    }
  
    // Cierra la ventana después de intentar guardar todos los modelos
    onClose();
  };
  
  // const handleSaveEvse = async () => {
  //   toast.info("Guardando nuevos modelos...");
  //   for (let index = 0; index < count; index++) {
  //     const body = {
  //       brand_id: data.id,
  //       model_name: evses[evses.length - (1 + index)].model_name
  //     }
  //     try {
  //       await modelConnectorService.createEvse(body);
  //       toast.success(`Se creo correctamente el modelo ${body.model_name}`);
  //     } catch (error) {
  //       toast.error(`No se logro crear el modelo ${body.model_name}`);
  //     }
  //   }
  //   onClose();
  // }

  const handleAddModel = () => {
    if(model !== ""){
      evses.push({
        id: "---",
        brand_name: data.name,
        model_name: model
      });
  
      setevses(evses);
      setModel("");
      setCount(count + 1);
      toast.success(`Modelo "${model}" agregado temporalmente.`);
    } else {
      toast.error("El nombre del modelo no puede estar vacío.");
    }
  }

  const getEvses = () => {
    return (
      <Card.Content>
        <div style={{display: "flex", textAlign: "center"}}>
          <Input
            label="Modelo"
            placeholder="Nombre del modelo"
            value={model}
            onChange={(e) => setModel(e.target.value)}
          />
          <CommonButton
            onClick={() => handleAddModel()}
            icon="add"
            label="Nuevo modelo"
            mode="primary"
            size="tiny"
            className="ml-2"
          />
        </div>
      </Card.Content>
    );
  }

  return (
    <>
      <Card fluid>
        <Card.Content>
          <Card.Header>
            Modelos relacionados
          </Card.Header>
        </Card.Content>
          {getEvsesByBrand()}
        <Card.Content>
          <Card.Header>
            Agregar nuevo modelo
          </Card.Header>
        </Card.Content>
          {getEvses()}
        <Card.Content extra textAlign="right">
          <>
            <CommonButton
              onClick={onClose}
              icon="close"
              label="Cancelar"
              mode="secondary"
              size="tiny"
            />
            <CommonButton
              onClick={handleSaveEvse}
              icon="save"
              label="Guardar"
              mode="primary"
              size="tiny"
            />
          </>
        </Card.Content>
      </Card>
    </>
  );
};

export default BrandConnectorReview;
