import { Card, Form, Label, Modal } from "semantic-ui-react";
import CommonButton from "./commonButton";
import { useState } from "react";
interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message: string;
  title?: string;
  showText?: boolean;
  changesDescription?: string;
  setChangesDescription?: (changesDescription: string) => void
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  onClose,
  onConfirm,
  message,
  title = "Confirmar Acción",
  showText,
  changesDescription = "",
  setChangesDescription
}) => {

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const handleOnConfirm = () => {
    if (showText && changesDescription !== null && changesDescription.length < 20){
        let message =
          changesDescription.length > 0
            ? "La descripción mínima es de (20) caracteres"
            : "La descripción no puede quedar en blanco";
        setErrorMessage(message); 
        return;
    }
    onConfirm();
  };

  return (
    <Modal size='tiny' open={open} onClose={onClose} trigger={<div />}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <p>{message}</p>
        {showText && setChangesDescription && (
          <Card.Content>
            <Form>
              <Label>Comentarios:</Label>
              <Form.TextArea
                value={changesDescription}
                onChange={(e, { value }) => {
                  setErrorMessage(undefined);
                  setChangesDescription(value as string);
                }}
                placeholder="Ingrese una breve descripción de los cambios solicitados"
                style={{ resize: "none" }}
                error={errorMessage}
              />
            </Form>
          </Card.Content>
        )}
      </Modal.Content>
      <Modal.Actions>
        <CommonButton
          size="small"
          onClick={handleOnConfirm}
          label="Aceptar"
        />
        <CommonButton
          mode="secondary"
          size="small"
          onClick={onClose}
          label="Cancelar"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmationModal;
