import React, { useEffect, useState } from "react";
import { Card, Checkbox, Modal, Table } from "semantic-ui-react";
import { CommonButton } from "../../../../../../components";

const DEFAULT_HOURS = [
  { weekday: 1, period_begin: "", period_end: "" },
  { weekday: 2, period_begin: "", period_end: "" },
  { weekday: 3, period_begin: "", period_end: "" },
  { weekday: 4, period_begin: "", period_end: "" },
  { weekday: 5, period_begin: "", period_end: "" },
  { weekday: 6, period_begin: "", period_end: "" },
  { weekday: 7, period_begin: "", period_end: "" },
];
export interface InSchedule {
  day: string;
  hours: number[];
}
export interface InScheduleFormat {
  weekday: number;
  period_begin: string;
  period_end: string;
}

export interface InPropsSchedule {
  week: string[];
  schedule: InScheduleFormat[];
}
interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: (props: InPropsSchedule) => void;
  initialSchedule?: InScheduleFormat[];
}
export const daysOfWeek = [
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
  "Domingo",
];

const ScheduleSelectionModal: React.FC<Props> = ({
  open,
  onClose,
  onSuccess,
  initialSchedule,
}) => {
  const [schedule, setSchedule] = useState<InSchedule[]>(
    daysOfWeek.map((day) => ({ day, hours: [] }))
  );

  useEffect(() => {
    if (initialSchedule) {
      const default_type = DEFAULT_HOURS;
      const week = getValueDays(default_type.filter(item=>item.period_begin));
      const result: { [day: string]: number[] } = {};
      const dataInitial = initialSchedule.concat(
        default_type.filter(itemA => 
          !initialSchedule.some(itemB => itemB.weekday === itemA.weekday)
        )
      );
      dataInitial.forEach((schedule) => {
        const { weekday, period_begin, period_end } = schedule;
        const day = daysOfWeek[weekday - 1];
        const hours = generateFormatHours(period_begin, period_end);
        if (!result[day]) {
          result[day] = [];
        }
        result[day] = [...result[day], ...hours];
      });

      const formatTablet: InSchedule[] = Object.entries(result).map(
        ([day, hours]) => {
          return { day, hours };
        }
      );
      setSchedule(formatTablet);
      onSuccess({
        schedule: default_type,
        week,
      });
    } 
    //eslint-disable-next-line
  }, []);

  const generateFormatHours = (
    period_begin: string,
    period_end: string
  ): number[] => {
    const begin = parseInt(period_begin.split(":")[0]);
    const end = parseInt(period_end.split(":")[0]);
    const hours = [];
    for (let i = begin; i <= end; i++) {
      hours.push(i);
    }
    return hours;
  };

  const handleCheckboxChange = (dayIndex: number, hour: number) => {
    const updatedSchedule = [...schedule];
    const day = updatedSchedule[dayIndex];
    const indexOfHour = day.hours.indexOf(hour);
    if (indexOfHour !== -1) {
      day.hours.splice(indexOfHour, 1);
    } else {
      day.hours.push(hour);
    }
    setSchedule(updatedSchedule);
  };

  const saveSchedule = () => {
    const formattedSchedule: {
      weekday: number;
      period_begin: string;
      period_end: string;
    }[] = [];

    schedule.forEach((day, dayIndex) => {
      const sortedHours = day.hours.sort((a, b) => a - b);
      let period_begin = "";
      let period_end = "";

      sortedHours.forEach((hour, index) => {
        if (index === 0 || hour !== sortedHours[index - 1] + 1) {
          period_begin = hour < 10 ? `0${hour}:00` : `${hour}:00`;
          period_end = "";
        }

        if (
          index === sortedHours.length - 1 ||
          hour !== sortedHours[index + 1] - 1
        ) {
          if (hour < 9) {
            period_end = `0${hour}:59`;
          } else if (hour === 23) {
            period_end = "23:59";
          } else {
            period_end = `${hour}:59`;
          }

          formattedSchedule.push({
            weekday: dayIndex + 1,
            period_begin,
            period_end,
          });
        }
      });
    });
    const week = getValueDays(formattedSchedule);
    console.log(JSON.stringify({ schedule: formattedSchedule, week }))
    onSuccess({ schedule: formattedSchedule, week });
    onClose();
  };

  const getValueDays = (formatDays: InScheduleFormat[]) => {
    let week: string[] = [];
    for (const day of formatDays) {
      const nameDay = daysOfWeek[day.weekday - 1];
      if (!week.includes(nameDay)) week.push(nameDay);
    }
    return week;
  };

  const replicateScheduleFromMonday = () => {
    const updatedSchedule = [...schedule]
    //eslint-disable-next-line
    updatedSchedule.map((day) => {
      day.hours = [...schedule[0].hours]
    })
    setSchedule(updatedSchedule)
  }

  const setScheduleBloqueDay = () => {
    const updatedSchedule = [...schedule]
    //eslint-disable-next-line
    updatedSchedule[0].hours = [6,7,8,9,10,11];
    setSchedule(updatedSchedule)
  }

  const setScheduleBloqueNigth = () => {
    const updatedSchedule = [...schedule]
    //eslint-disable-next-line
    updatedSchedule[0].hours = [14,15,16,17,18,19,20];
    setSchedule(updatedSchedule)
  }

  const clearSchedule = () => {
    const updatedSchedule = [...schedule]
    //eslint-disable-next-line
    updatedSchedule.map((day) => {
      day.hours = []
    })
    setSchedule(updatedSchedule)
  }

  return (
    <Modal open={open} onClose={onClose} size="large">
      <Modal.Header>Horario de atención</Modal.Header>
      <div style={{ margin: '10px 20px 0'}}>
        <Card fluid>
          <Card.Content>
            <Card.Header>Generar horario</Card.Header>
            <Card.Description>Presionar <b>"Bloque mañana"</b> para configurar el horario del lunes de 06:00 a 11:00.</Card.Description>
            <Card.Description>Presionar <b>"Bloque noche"</b> para configurar el horario del lunes de 14:00 a 21:00.</Card.Description>
            <Card.Description>Puede completar el horario en el día lunes y luego presionar <b>"Replicar lunes para otros días"</b> para hacer una copia de las horas.</Card.Description>
          </Card.Content>
          <Card.Content extra>
          <CommonButton
            mode="primary"
            size="tiny"
            onClick={replicateScheduleFromMonday}
            label="Replicar lunes para otros días"
          ></CommonButton>
          <CommonButton
            mode="primary"
            size="tiny"
            onClick={setScheduleBloqueDay}
            label="Bloque mañana"
          ></CommonButton>
          <CommonButton
            mode="primary"
            size="tiny"
            onClick={setScheduleBloqueNigth}
            label="Bloque noche"
          ></CommonButton>
          <CommonButton
            mode="secondary"
            size="tiny"
            onClick={clearSchedule}
            label="Limpiar horario"
          ></CommonButton>
          </Card.Content>
        </Card>
      </div>
      <Modal.Content scrolling>
        <Table celled >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              {[...Array(24)].map((_, hour) => (
                <Table.HeaderCell key={hour}>{`${hour}:00-${hour}:59`}</Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {schedule.map((day, dayIndex) => (
              <Table.Row key={dayIndex}>
                <Table.Cell className="text-bold">{day.day}</Table.Cell>
                {[...Array(24)].map((_, hour) => (
                  <Table.Cell key={hour}>
                    <Checkbox
                      checked={day.hours.includes(hour)}
                      onChange={() => handleCheckboxChange(dayIndex, hour)}
                    />
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <CommonButton label="Guardar" onClick={saveSchedule} />
        <CommonButton
          mode="secondary"
          onClick={onClose}
          label="Cerrar"
        ></CommonButton>
      </Modal.Actions>
    </Modal>
  );
};

export default ScheduleSelectionModal;
