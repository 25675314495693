import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  Grid,
  Segment,
} from "semantic-ui-react";
import { CommonModal, CommonTable } from "../../../components";
import { HEADER_OWNER_TABLE } from "../homeConstant";
import { toast } from "react-toastify";
import { getLocationsService } from "../../../fetch/services/locations.services";
import moment from "moment";
import { InLocationsItems } from "../../../fetch/types/locations.types";
import { useLocation } from "../../../shared/context/locationContext";
import useSessionStore from "../../../store/useSession";
import { getAllUserOwner } from "../../../fetch/services/users.services";
import useModal from "../../../shared/hooks/useModal";
import ConfirmT6SEC from "../../approvalRejection/components/ConfirmT6SEC";

interface InUserFilter {
  owner: { id: number; text: string; value: string }[];
}

type InOwnerFilter = {
  isLoading: boolean;
  onSubmit: (filter: Record<string, unknown>) => void;
};

const OwnerSection: React.FC<{}> = () => {
  const [modalTitle, modalContent, isModalOpen, openModal, closeModal] = useModal();
  const [paginationControl, setPaginationControl] = useState({
    currentPage: 1,
    totalPages: 1,
  });
  const [itemsLocations, setItemsLocations] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [currentFilter, setCurrentFilter] = useState<{
    commune?: string;
    region?: string;
    role_id?: number;
    user_id?: number;
    order_parameter?: string,
    order_rule?: string,
    limit?: number,
  }>({});

  useEffect(() => {
    const getLocations = async () => {
      const getPSE = (item?: InLocationsItems["PSEs"]) => {
        if (!item || item.length < 1) return "---";
        if (item.length > 1) return `${item?.length} PSE Asignado(s)`;
        return item[0].name;
      };

      try {
        setLoading(true);
        const { total_pages, items } = await getLocationsService(currentFilter);
        setPaginationControl({ ...paginationControl, totalPages: total_pages });
        const _items = items.map((element) => {
          return {
            ...element,
            last_updated: moment(element.last_updated).format("DD MMMM YYYY hh:mm"),
            created_at: moment(element.revision_date).format("DD MMMM YYYY hh:mm"),
            pse: getPSE(element?.PSEs),
            opc: element?.OPC?.name || "---",
            datos_IRVE_confirmados: element?.datos_IRVE_confirmados ? "Si" : "NO",
            owner: element?.owner?.name || "---",
            total_evses: element.evses.length,
            total_connectors: element.evses
              .map((item) => item.connectors.length)
              .reduce((a, b) => a + b, 0),
          };
        });
        setItemsLocations(_items);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("Ocurrio un error al realizar la petición");
      }
    };
    getLocations();
    // eslint-disable-next-line
  }, [paginationControl.currentPage, currentFilter]);

  const handlePageChange = (
    event: React.SyntheticEvent,
    { activePage }: any
  ) => {
    setCurrentFilter((prevFilters) => ({
      ...prevFilters,
      page: activePage,
    }));
  };

  const tableActions = ({ openModal, closeModal }: any): any[] => {

    return [
      {
        icon: "edit",
        hint: "Modificar información folio",
        handler: (event: any) =>
          openModal(
            `Folio ${event.folio_IRVE}`,
            <ConfirmT6SEC folioId={event.folio_IRVE.toString()} onClose={closeModal} />
          ),
      },
    ];
  };

  const handleTableInteraction = (interactionData: any) => {
    console.log('-->interactionData', interactionData)
    if(["name","created_at","commune","region","last_updated","owner","opc", "total_evses", "total_connectors"].includes(interactionData?.parameter)){
      setCurrentFilter((prevFilters) => ({
        ...prevFilters,
        page: 1,
        order_parameter: interactionData.parameter,
        order_rule: interactionData.rule
      }));
    }
    if(interactionData?.numberItems){
      setCurrentFilter((prevFilters) => ({
        ...prevFilters,
        limit: interactionData.numberItems
      }));
    }
  };

  return (
    <Container className="mt-4 w-100">
      <CommonTable
        title="Revisión de instalaciones"
        headerList={HEADER_OWNER_TABLE}
        items={itemsLocations}
        actions={tableActions({ openModal, closeModal })}
        currentPage={paginationControl.currentPage}
        totalPages={paginationControl.totalPages}
        onHandlePagination={handlePageChange}
        isLoading={loading}
        onInteraction={handleTableInteraction}
        additionalComponent={
          <FilterTableOwnerAndOPC
            isLoading={loading}
            onSubmit={(data) => setCurrentFilter(data)}
          />
        }
      />

      <CommonModal
        isOpen={isModalOpen}
        content={modalContent}
        onClose={closeModal}
        title={modalTitle}
      />
    </Container>
  );
};

export default OwnerSection;

export const FilterTableOwnerAndOPC = ({ isLoading, onSubmit }: InOwnerFilter) => {
  const { profile } = useSessionStore();
  const { regions, communes, getCommuneByRegion } = useLocation();
  const [users, setUsers] = useState<InUserFilter>({ owner: [] });
  const [filterValues, setFilterValues] = useState({
    folio_irve: "",
    name: "",
    commune: "",
    region: "",
    owner_id: "",
  });
  useEffect(() => {
    const getUsers = async () => {
      try {
        if (!profile || !profile.currentRole?.roleId) return;
        const users = await getAllUserOwner();
        
        if (users.length > 0) {
          const _usr = users.map((item) => ({
            id: item.business_details_id,
            text: item.name,
            value: String(item.business_details_id),
          }));
          const init: InUserFilter = {
            owner: _usr,
          };
          setUsers(init);
        }
      } catch (error) {
        console.log("Error al cargar los usuarios");
      }
    };

    getUsers();
    // eslint-disable-next-line
  }, []);

  const handleSearch = () => {
    let allFilter: any = { ...filterValues };

    const filteredData = Object.fromEntries(
      Object.entries(allFilter).filter(([_, value]) => value !== "")
    );
    onSubmit(filteredData);
  };

  return (
    <Segment basic className="mx-0 px-0">
      <Grid className="h-100 mx-0 px-0" centered>
        <Grid.Row className="p-0 mx-0">

          <Grid.Column width={16} only="computer" className="mx-0 px-0">
            <Form onSubmit={(e) => e.stopPropagation()}>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Form.Group widths={3}>
                    <Form.Input
                      type="text"
                      label="Folio"
                      placeholder="Buscar por Folio"
                      value={filterValues.folio_irve}
                      className="mr-4"
                      onChange={({ target: { value } }) => {
                        setFilterValues({
                          ...filterValues,
                          folio_irve: value.replace(/[^0-9]/g, ""),
                        });
                      }}
                    />
                    <Form.Input
                      label="Nombre Instalación"
                      type="text"
                      placeholder="Buscar por Nombre Instalación"
                      value={filterValues.name}
                      className="mr-4"
                      onChange={({ target: { value } }) =>
                        setFilterValues({
                          ...filterValues,
                          name: value,
                        })
                      }
                    />

                    <Form.Select
                      search
                      clearable
                      label="Región"
                      options={regions}
                      type="text"
                      placeholder="Buscar por Región"
                      className="mr-4"
                      onChange={(_, data) =>
                        setFilterValues({
                          ...filterValues,
                          region: String(data.value),
                          commune: "",
                        })
                      }
                      value={filterValues.region}
                    />
                  </Form.Group>
                </Grid.Column>

                <Grid.Column mobile={16} computer={4}>
                  <Form.Group widths={"equal"}>
                    <Form.Select
                      clearable
                      search
                      label="Comuna"
                      options={
                        filterValues.region
                          ? getCommuneByRegion(filterValues.region)
                          : communes
                      }
                      placeholder="Buscar por Comuna"
                      className="mr-4"
                      onChange={(_, data) =>
                        setFilterValues({
                          ...filterValues,
                          commune: String(data.value),
                        })
                      }
                      value={filterValues.commune}
                    />
                    <Form.Select
                      search
                      clearable
                      label={"Propietario"}
                      options={users.owner}
                      placeholder="Buscar por Propietario1"
                      className="mr-4"
                      onChange={(_, data) =>
                        setFilterValues({
                          ...filterValues,
                          owner_id: String(data.value),
                        })
                      }
                      value={filterValues.owner_id}
                    />
                  </Form.Group>
                </Grid.Column>

                <Grid.Column mobile={16} computer={4}>
                  <Form.Group widths={"equal"}>
                    <Form.Button
                      content="Buscar"
                      primary
                      className="d-flex justify-end mt-3"
                      onClick={handleSearch}
                      icon="search"
                      loading={isLoading}
                      style={{ marginRight: "1.8rem" }}
                    />
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
