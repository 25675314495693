import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  Segment,
  Grid,
  Label,
  Button,
  Message,
  Modal,
} from "semantic-ui-react";
import { useApprovalRejectionContext } from "../../context";
import {
  ROLES_NAME,
  StatusApprovals,
  TYPE_ACTIVITY,
} from "../../../../shared/constants";
import { ApprovalData } from "../../types";

import "./styles.scss";
import { contentReviewData } from "../../content";
import { CommonButton, CommonConfirmationModal } from "../../../../components";
import { ApprovalStatusData } from "../../../../shared/types";
import ConfirmT6Content from "./components/confirmT6";
import useSessionStore from "../../../../store/useSession";
import ModalInformation from "../../../home/sections/components/modal/Owner/modalInformation";
import { toast } from "react-toastify";
import useFileUpload from "../../../../shared/hooks/useFileUpload";
import { getLocationsService } from "../../../../fetch/services/locations.services";
import { InLocationsItems } from "../../../../fetch/types/locations.types";
const { APROBADO, PENDIENTE, RECHAZADO } = StatusApprovals;
const {
  CONFIRM_TE6,
  TRASPASO_FOLIO,
  ASSIGNMENT_CPO,
  AUTO_ASSIGNMENT,
  ASSIGNMENT_PSE,
  NEW_USER,
} = TYPE_ACTIVITY;

interface Props {
  data: ApprovalData;
  onClose: () => void;
  onAction: (data: ApprovalStatusData) => void;
}

const ApprovalScreen: React.FC<Props> = ({ data, onClose, onAction }) => {
  const { profile } = useSessionStore();
  const { handleDownload } = useFileUpload();
  const [infoFolio, setInfoFolio] = useState<InLocationsItems>(
    {} as InLocationsItems
  );
  const [loginFolio, setLoginFolio] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [links, setLinks] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<number>();
  const [changesDescription, setChangesDescription] = useState<string>("");
  // const [showChangesSection, setShowChangesSection] = useState(false);
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const { columnLeft, columnRight } = contentReviewData(data);
  const { enums } = useApprovalRejectionContext();

  useEffect(() => {
    const getLinks = async () => {
      try {
        const { approvalId, location, activity } = data;
        if (activity === NEW_USER) {
          if (!profile)
            return toast.error(
              "Ocurrio un error al intentar descargar el documento"
            );
          const path: any = await handleDownload("documents", data.user.rut);
          if (path) {
            setLinks(path);
          }
        } else if (activity === CONFIRM_TE6) {
          const path: any = await handleDownload(
            "locations",
            location.location_id
          );
          const fileConfig = path.filter((item: string[]) =>
            item.includes("images/1.pdf")
          );
          setLinks(fileConfig);
        } else {
          if (!approvalId && !location.location_id)
            return toast.error(
              "Ocurrio un error al intentar descargar el documento"
            );
          const path: any = await handleDownload(
            "locations",
            location.location_id,
            approvalId.toString()
          );
          if (path) {
            setLinks(path);
          }
        }
      } catch (error) {
        toast.error("Ocurrio un error al intentar obtener los adjuntos");
      }
    };
    getLinks();
    if (data.activity !== NEW_USER && data.activity !== CONFIRM_TE6) {
      getLocationsById();
    }
    // eslint-disable-next-line
  }, [data]);

  const getLocationsById = async () => {
    try {
      if (loginFolio) return;
      setLoginFolio(true);
      const locations = await getLocationsService({
        folio_irve: String(data.folio),
      });
      if (locations.items.length > 0) {
        setInfoFolio(locations.items[0]);
      }
      setLoginFolio(false);
    } catch (error) {
      setLoginFolio(false);

      toast.error("Ocurrio un error al obtener los datos del folio");
    }
  };

  const downloadFile = async () => {
    try {
      if (links.length > 0) {
        const filename = links[0]
          .substring(links[0].lastIndexOf("/") + 1)
          .split("?")[0];
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = function () {
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(xhr.response as Blob); // xhr.response is a blob
          a.download = filename;
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a); // Clean up.
        };
        xhr.open("GET", links[0]);
        xhr.send();
      } else return toast.info("La solicitud no tiene Anexo");
    } catch (error) {
      console.log(error);
    }
  };

  const filteredOptions = enums.filter(
    (option: any) => option.value !== data.status && option.value !== PENDIENTE
  );
  const hasApprovedStatus = data.status !== APROBADO;
  const getValueFromId = (id: number): string | undefined =>
    enums.find((option: any) => option.id === id)?.value;
  const showNoSupervisorMessage = data.status === PENDIENTE;

  const handleChangeStatus = () => {
    onAction({
      approvalId: data.approvalId,
      approvalStatusId: selectedStatus,
      message: changesDescription,
      activity: data.activity,
    });
    onClose();
  };

  const isBlocked = useMemo(() => {
    if (!profile) return true;
    switch (profile.currentRole?.nameRol) {
      case ROLES_NAME.SEC:
        if (data.sec?.business_details_id === profile.userId) {
          return [APROBADO, RECHAZADO].includes(data.status);
        }else if (data.activity === TYPE_ACTIVITY.AUTO_ASSIGNMENT){
          return false;
        }
        return true;

      case ROLES_NAME.OWNER:
      case ROLES_NAME.OPC:
      case ROLES_NAME.CPO:
      case ROLES_NAME.PSE:
        switch (data.activity) {
          case ASSIGNMENT_CPO:
          case ASSIGNMENT_PSE:
          case TRASPASO_FOLIO:
            if (data.userTarget?.business_details_id === profile.userId) {
              return [APROBADO, RECHAZADO].includes(data.status);
            }
            return true;

          case AUTO_ASSIGNMENT:
            if (data?.userTarget?.business_details_id === profile.userId) {
              return [APROBADO, RECHAZADO].includes(data.status);
            }
            return true;

          default:
            return true;
        }

      default:
        return true;
    }
  }, [data, profile]);

  const getRenderApproved = () => {
    switch (data.activity) {
      case CONFIRM_TE6:
        return <ConfirmT6Content info={data} />;
      case NEW_USER:
        return (
          <Card.Content>
            <Grid columns={2}>
              <Grid.Column>
                {columnLeft.map((content, index) => (
                  <>
                    {content.label !== "Folio" && (
                      <Grid.Row key={index}>
                        <Grid.Column>
                          <Label className="w-100">
                            {content.label === "Fecha de Instalación"
                              ? "Fecha de Creación"
                              : content.label}
                            :
                          </Label>
                        </Grid.Column>
                        <Grid.Column className="ml-2">
                          <span>{content.value}</span>
                        </Grid.Column>
                      </Grid.Row>
                    )}
                  </>
                ))}
              </Grid.Column>
              <Grid.Column>
                {columnRight.map((content, index) => (
                  <>
                    {content.label !== "Número de Cargadores" && (
                      <Grid.Row key={index}>
                        <Grid.Column>
                          <Label className="w-100">{content.label}:</Label>
                        </Grid.Column>
                        <Grid.Column className="ml-2">
                          <span>{content.value}</span>
                        </Grid.Column>
                      </Grid.Row>
                    )}
                  </>
                ))}
              </Grid.Column>
            </Grid>
          </Card.Content>
        );

      default:
        return (
          <Card.Content>
            <Grid columns={2}>
              <Grid.Column>
                {columnLeft.map((content, index) => (
                  <Grid.Row key={index}>
                    <Grid.Column>
                      <Label className="w-100">{content.label}:</Label>
                    </Grid.Column>
                    <Grid.Column className="ml-2">
                      {content.label === "Folio" && !loginFolio ? (
                        <span
                          onClick={() => setOpenModal(true)}
                          className="pointer"
                          style={{
                            color: "darkblue",
                            textDecoration: "underline",
                            textUnderlineOffset: "2px",
                          }}
                        >
                          {content.value}
                        </span>
                      ) : (
                        <span>{content.value}</span>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                ))}
              </Grid.Column>
              <Grid.Column>
                {columnRight.map((content, index) => (
                  <Grid.Row key={index}>
                    <Grid.Column>
                      <Label className="w-100">{content.label}:</Label>
                    </Grid.Column>
                    <Grid.Column className="ml-2">
                      <span>{content.value}</span>
                    </Grid.Column>
                  </Grid.Row>
                ))}
                {[ASSIGNMENT_CPO, ASSIGNMENT_PSE, ASSIGNMENT_CPO].includes(
                  data.activity
                ) &&
                  data.userTarget?.business_details_id === profile?.userId && (
                    <Message
                      className="text-center"
                      info
                      header={data.activity}
                      content="Al aceptar la asignación del nuevo rol, por favor ten en cuenta que será necesario cerrar sesión y volver a iniciar sesión para ver los cambios reflejados."
                    />
                  )}
              </Grid.Column>
            </Grid>
          </Card.Content>
        );
    }
  };

  const buttons = () => {
    let options = filteredOptions
      // Se oculta filtro para no mostrar la opcion SE SOLICITAN CAMBIOS a peticion del cliente.
      // La funcionalidad igualmente esta funcional solo queda deshabilitada.
      .filter((option: any) => option.value !== "SE SOLICITAN CAMBIOS")
      .map((option: any) => ({
        text: option.value,
        value: option.id,
    }));

    const order: { [key: string]: number } = { "APROBADO": 1, "RECHAZADO": 2, "PENDIENTE": 3, "EN PROGRESO": 4, "SE SOLICITAN CAMBIOS": 5 };

    options.sort((a:any, b:any) => {
        return (order[a.text as string] || 0) - (order[b.text as string] || 0);
    });
    options = options.map((x: any) => {
      const color = x.text === "APROBADO" ? "green" : x.text === "RECHAZADO" ? "red" : "yellow"
      return (
        <Button
          className="mr-2"
          content={x.text}
          color={color}
          onClick={() => { handleConfirm(x.value) }}
          disabled={isBlocked}
        />
      );
    })
    return options
  }

  const handleConfirm = (value: number) => {
    setSelectedStatus(value);
    setModalConfirmation(true);
  };

  return (
    <>
      {!showNoSupervisorMessage && (
        <div>
          <Segment className="d-flex align-center justify-between">
            <Label>{data.status}</Label>
            <div className="d-flex justify-end">
              <Button
                content="Descargar anexo"
                icon="file pdf"
                labelPosition="left"
                color="blue"
                onClick={downloadFile}
                disabled={!Boolean(links.length)}
              />
            </div>
          </Segment>
          { hasApprovedStatus && data.status === "EN PROGRESO" ? (
            <Segment className="d-flex align-center justify-start">
              {buttons()}
            </Segment>) : 
          null }
        </div>
      )}
      {showNoSupervisorMessage &&
        ![
          ROLES_NAME.OWNER,
          ROLES_NAME.OPC,
          ROLES_NAME.PSE,
          ROLES_NAME.CPO,
        ].includes(profile?.currentRole?.nameRol || "") && (
          <Message
            icon="info circle"
            header="Atención"
            content="Asuma la revisión como supervisor SEC para poder gestionarla o solicitar cambios."
            color="yellow"
          />
        )}
      <Card fluid>
        <Card.Content>
          <Card.Header>
            {NEW_USER === data.activity
              ? "Detalles Nuevo Usuario"
              : "Detalles del Folio"}
          </Card.Header>
        </Card.Content>
        {getRenderApproved()}
        <Card.Content extra textAlign="right">
          <>
            <CommonButton
              onClick={onClose}
              icon="close"
              label="Cancelar"
              mode="secondary"
              size="tiny"
            />
          </>
        </Card.Content>
      </Card>
      <CommonConfirmationModal
        open={modalConfirmation}
        onClose={() => setModalConfirmation(false)}
        onConfirm={handleChangeStatus}
        message={`¿Está seguro de cambiar el estado de la revisión a ${getValueFromId(
          selectedStatus as number
        )}?`}
        changesDescription={changesDescription}
        setChangesDescription={setChangesDescription}
        showText={[5,3].includes(selectedStatus as number)}
      />

      <Modal open={openModal} onClose={() => setOpenModal(false)} size="tiny">
        <ModalInformation
          onClose={() => setOpenModal(false)}
          info={infoFolio}
        />
      </Modal>
    </>
  );
};

export default ApprovalScreen;
