import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Segment,
  Menu,
  Icon,
  Image,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownDivider,
  Modal,
} from "semantic-ui-react";
import SidebarComponent from "./components/sidebar";
import "../container/home/sections/components/styles.scss";
import { Outlet, useNavigate } from "react-router-dom";
import ROUTES from "../router/route";
import { toast } from "react-toastify";
import "./components/styles.scss";
import useSessionStore, { InUserRoleSession } from "../store/useSession";
import { functionShared } from "../shared/functions";
import ConfirmationModal from "../components/common/CommonConfirmationModal";
import { LOGO_APP } from "../assets";
import { runFormater } from "../shared/utils/runService";
import CommonButton from "../components/common/commonButton";
import CommonInput from "../components/common/commonInput";
import CommonDropdown from "../components/common/commonDropdown";
import { TextEditUser } from "../container/login/constants/text";
import { useLocation } from "../shared/context/locationContext";
import { putUserAvailableService } from "../fetch/services/users.services";
interface LayoutProps {
  children?: any;
}

interface InUserInfo {
  username: string;
  name: string;
  email: string;
  phone: string;
  currentRol: string;
  roles: InUserRoleSession[];
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [openSidebar, setOpenSidebar] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openModalEditUser, setOpenModalEditUser] = useState(false);

  const [blockedUser, setBlockedUser] = useState(false);
  const [userInfo, setUserInfo] = useState<InUserInfo>({} as InUserInfo);
  const { profile, updateInfoUser, signOutStore, isLogged, closeSessions } = useSessionStore();

  const [formEditUser, setFormEditUser] = useState({
    email: profile?.email || '',
    commune: profile?.commune || '',
    region: profile?.region || '',
    phone: profile?.phone || '',
    direction: profile?.address || '',
    user_id: profile?.userId || '',
  })
  const navigate = useNavigate();

  useEffect(() => {
    const getInfoUser = async () => {
      try {
        if (!profile) return;
        //Se bloquea sidebar sino hay perfil definido
        if (!isLogged) {
          setOpenSidebar(false);
          setBlockedUser(true);
        } else {
          setBlockedUser(false);
          setOpenSidebar(true);
        }
        setFormEditUser({
          email: profile?.email || '',
          commune: profile?.commune || '',
          region: profile?.region || '',
          phone: profile?.phone || '',
          direction: profile?.address || '',
          user_id: profile?.userId || '',
        });

        setUserInfo({
          username: profile.rut,
          email: `${profile.email}`,
          name: `${profile.name}`,
          phone: `${profile.phone}`,
          roles: profile.roles || [],
          currentRol: profile.currentRole?.nameRolEsp || "Sin asignar",
        });
      } catch (error) {
        toast.error("Error al traer la información del usuario");
      }
    };
    getInfoUser();
  }, [profile, isLogged]);

  const handleToggleSidebar = () => {
    if (blockedUser) return;
    setOpenSidebar(!openSidebar);
  };

  const getChildrenClass = openSidebar
    ? "sidebar-content-children mt-0"
    : "sidebar-content-children mt-0 ml-0";

  const handleSignOut = async () => {
    try {
      await signOutStore();
      navigate(ROUTES.LOGIN);
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const handleLogoutRole = () => {
    closeSessions();
    setOpenModal(false);
    navigate(ROUTES.AUTH_PROFILE);
  };
  const renderAvatar = (name: string) => {
    if (!name) {
      name = "- -";
    }
    const initials = name
      .split(" ")
      .map((part) => part.charAt(0))
      .join("")
      .toUpperCase()
      .slice(0, 2);

    return (
      <div className="content-header-avatar-layout">
        <span>{initials}</span>
      </div>
    );
  };

  const isDisabled = useMemo(() => {
    if (!isLogged) return true;
    if (!Boolean(Object.keys(userInfo).length)) return true;
    if (userInfo?.roles.length < 2) return true;
  }, [isLogged, userInfo]);

  const { regions, communes, getCommuneByRegion } = useLocation();

  const handleEditUser = useCallback(
    (key: string) => (_value: any) => {
      let value = "";
      if (!!_value) value = _value;

      if (key === 'region') {
        setFormEditUser((prevState) => ({
          ...prevState,
          [key]: value,
          commune: ''
        }));
      } else {
        setFormEditUser((prevState) => ({
          ...prevState,
          [key]: value,
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formEditUser]
  );

  const [loading, setLoading] = useState(false)

  const handleSubmitEditUser = async () => {
    try {
      setLoading(true)
      if (validateFormEditUser()) {
        const data = await putUserAvailableService(formEditUser)
        console.log(data)
        if (!!data) {
          updateInfoUser({
              email: formEditUser.email,
              phone: formEditUser.phone,
              commune: formEditUser.commune,
              region: formEditUser.region,
              userId: Number(formEditUser.user_id),
              direction: formEditUser.direction,
          })
          setOpenModalEditUser(false)
          toast.success(TextEditUser.SUCCESS)
        }
      }
    } 
    catch (err) {console.log(err)}
    finally {setLoading(false)}
  }

  const validateFormEditUser = () => {
    if (
      formEditUser.email === profile?.email &&
      formEditUser.phone === profile.phone &&
      formEditUser.direction === profile.address &&
      formEditUser.commune === profile.commune &&
      formEditUser.region === profile.region
    ) {
      setOpenModalEditUser(false)
      toast.error(TextEditUser.DATA_INVALID)
      return false
    }
    if (formEditUser.region !== profile?.region && 
      (formEditUser.commune === '' || formEditUser.direction === '')
    ) {
      setOpenModalEditUser(false)
      toast.error(TextEditUser.REGION_PARTIAL)
      return false
    }
    if (formEditUser.commune !== profile?.commune && formEditUser.direction === ''
    ) {
      setOpenModalEditUser(false)
      toast.error(TextEditUser.COMUNE_PARTIAL)
      return false
    }
    return true
  }

  return (
    <div
      className="h-100 layout-general-container"
      style={{
        marginRight: "1rem",
        marginLeft: openSidebar ? "215px" : "15px",
        transition: "margin-left 0.5s ease",
      }}
    >
      <Menu className="content-header-layout px-2" fixed="top">
        <Menu.Item onClick={handleToggleSidebar}>
          <Icon name="sidebar" />
        </Menu.Item>

        <Menu.Item header>
          <Image src={LOGO_APP} size="small"/>
        </Menu.Item>

        <Menu.Item header className="hidden-mobile px-0">
          <span className="text-header-platform-bar">
            Plataforma de Interoperabilidad
          </span>
        </Menu.Item>

        <Menu.Menu
          className="hidden-mobile header-avatar-layout"
          position="right"
        >
          <Menu.Item>
            <Dropdown
              inline
              className="header-dropdown-avatar-layout"
              trigger={
                <div className="mt-1 d-flex flex-column align-center">
                  {renderAvatar(userInfo.name)}
                  <span style={{ fontSize: 10, color: "white" }}>
                    {userInfo.currentRol}
                  </span>
                </div>
              }
            >
              <DropdownMenu>
                <DropdownItem
                  text="Nombre"
                  description={functionShared.shortName(
                    userInfo?.name || "NU",
                    15
                  )}
                />
                <DropdownItem text="Rol" description={userInfo.currentRol} />
                <DropdownItem
                  text="RUT"
                  description={runFormater(userInfo.username)}
                />
                <DropdownDivider />
                <DropdownItem
                  icon="edit"
                  text="Modificar mis datos"
                  onClick={() => setOpenModalEditUser(true)}
                />
                <DropdownItem
                  icon="users"
                  text="Cambiar de rol"
                  onClick={() => setOpenModal(true)}
                  disabled={isDisabled}
                />
                <DropdownItem
                  icon="log out"
                  text="Cerrar sesión"
                  onClick={handleSignOut}
                />
              </DropdownMenu>
            </Dropdown>
          </Menu.Item>
        </Menu.Menu>
      </Menu>

      <SidebarComponent
        visible={openSidebar}
        onToggle={handleToggleSidebar}
        onLogout={handleSignOut}
      />
      <Segment
        className={getChildrenClass}
        style={{ top: '80px' }}
      >
        <Outlet />
      </Segment>

      <ConfirmationModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        message="Cambiar de rol modificará tus permisos y acceso dentro de la plataforma. ¿Desea continuar con la operación?"
        title="¿Estás seguro de que deseas cambiar de rol?"
        onConfirm={handleLogoutRole}
      />

      <Modal
        open={openModalEditUser}
        onClose={() => setOpenModalEditUser(false)}
        size={'mini'}
        closeIcon={true}
        dimmer={'blurring'}
      >
        <Modal.Header>Modificar mis datos</Modal.Header>
        <Modal.Content>
          <div>
            <CommonInput
              label="Email"
              placeholder="Ingrese su email"
              onChange={e => handleEditUser("email")(e.target.value)}
              value={formEditUser.email}
            />
            <CommonInput
              label="Telefono"
              placeholder="Ingrese su numero"
              onChange={e => handleEditUser("phone")(e.target.value)}
              value={formEditUser.phone}
            />
            <CommonDropdown
              label="Región"
              value={formEditUser.region}
              options={regions}
              onChange={(e) => handleEditUser("region")(e && e.value ? e.value : null)}
            />
            <CommonDropdown
              label="Comuna"
              value={formEditUser.commune}
              options={formEditUser.region
                ? getCommuneByRegion(formEditUser.region)
                : communes}
              onChange={(e) => handleEditUser("commune")(e && e.value ? e.value : null)}
            />
            <CommonInput
              label="Dirección"
              placeholder="Ingrese su dirección"
              onChange={e => handleEditUser("direction")(e.target.value)}
              value={formEditUser.direction}
            />
          </div>
          <CommonButton
            className="mt-2 text-center"
            mode="primary"
            label="Guardar"
            onClick={handleSubmitEditUser}
            loading={loading}
          />
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default Layout;
