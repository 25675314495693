import React, { useEffect, useState } from "react";
import { Container, Modal } from "semantic-ui-react";
import { Action, CommonTable } from "../../../components";
import { HEADER_OPC_TABLE } from "../homeConstant";
import ModalConfirmation from "./components/modal/CPO/modalConfirmation";
import ModalDelete from "./components/modal/CPO/modalDelete";
import { getLocationsService } from "../../../fetch/services/locations.services";
import moment from "moment";
import { toast } from "react-toastify";
import {
  InLocationRequest,
  InLocationsItems,
} from "../../../fetch/types/locations.types";
import ModalEditDataVariables from "./components/modal/Owner/modalEditData";
import useSessionStore from "../../../store/useSession";
import { ApprovalRejectionProvider } from "../../approvalRejection/context";
import { ROLES_NAME } from "../../../shared/constants";
import ModalInformationOPC from "./components/modal/CPO/modalInformation";
import { FilterTableOwnerAndOPC } from "./owner.sections";

type InTypeModal = "delete" |"confirmation" | "edit" | "info" ;

const OPCSections: React.FC<{}> = () => {
  const [openModal, setOpenModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [infoFolio, setInfoFolio] = useState<InLocationsItems>(
    {} as InLocationsItems
  );
  const [itemsLocations, setItemsLocations] = useState<
    InLocationRequest["items"]
  >([]);

  const [loading, setLoading] = useState(false);
  const { profile } = useSessionStore();

  const [paginationControl, setPaginationControl] = useState({
    currentPage: 1,
    totalPages: 1,
  });

  const [currentFilter, setCurrentFilter] = useState<{
    commune?: string;
    region?: string;
    user_id?: number;
    role_id?: number;
  }>({});

  const [typeModal, setTypeModal] = useState<InTypeModal>("edit");

  useEffect(() => {
    const getLocations = async () => {
      setLoading(true);
      try {
        currentFilter.user_id = profile?.userId;
        currentFilter.role_id = profile?.currentRole?.roleId;
        const { total_pages, items } = await getLocationsService(currentFilter);
        setPaginationControl({ ...paginationControl, totalPages: total_pages });

        const _items = items.map((element) => {
          return {
            ...element,
            last_updated: moment(element.last_updated).format("DD MMMM YYYY HH:mm"),
            revision_date: moment(element.revision_date).format("DD MMMM YYYY HH:mm"),
            admin: element?.owner?.name || "---",
            chargers: element.evses.length,
            connectors: element.evses
              .map((item) => item.connectors.length)
              .reduce((a, b) => a + b, 0),
          };
        });

        setItemsLocations(_items);
      } catch (error) {
        toast.error("Ocurrio un error al realizar la petición");
      } finally {
        setLoading(false);
      }
    };
    getLocations();
    // eslint-disable-next-line
  }, [paginationControl.currentPage, currentFilter, reload]);

  const handleModal = (type: InTypeModal, info: InLocationsItems) => {
    setTypeModal(type);
    setInfoFolio(info);
    setOpenModal(true);
  };

  const modalOptions = {
    info: <ModalInformationOPC folio={infoFolio} />,
    edit: (
      <ModalEditDataVariables
        info={infoFolio}
        onClose={() => setOpenModal(false)}
        onSuccess={() => setReload(!reload)}
      />
    ),
    confirmation: (
      <ModalConfirmation
        onClose={() => setOpenModal(false)}
        folio={infoFolio}
      />
    ),
    delete: (
      // <> {/* Componente o contenido temporal */}
      // <p>Funcionalidad de desasignación aún no implementada.</p>
      // </>

      <ModalDelete
        onClose={() => setOpenModal(false)}
        onSuccess={() => setReload(!reload)}
        locationId={infoFolio?.location_id}
      />
    ),
  };

  const commonActions: Action[] = [
    {
      icon: "eye",
      hint: "información folio",
      handler: (info: any) => handleModal("info", info),
      // hideIcon: (revision: any) => !revision.datos_IRVE_confirmados,
    },
    {
      icon: "edit",
      hint: "Edición datos variables",
      handler: (info: any) => handleModal("edit", info),
      hideIcon: (revision: any) => !revision.datos_IRVE_confirmados,
    },

    {
      icon: "warning circle",
      hint: "Confirmación datos folio",
      handler: (info: any) => handleModal("confirmation", info),
      hideIcon: () =>
        profile ? profile.currentRole?.nameRol === ROLES_NAME.PSE : false,
    },

    {
      icon: "trash",
      hint: "Desasignación Folio",
      handler: (info: any) => handleModal("delete", info),
    },
  ];

  const handlePageChange = (
    event: React.SyntheticEvent,
    { activePage }: any
  ) => {
    setCurrentFilter((prevFilters) => ({
      ...prevFilters,
      page: activePage,
    }));
  };

  return (
    <ApprovalRejectionProvider>
      <Container className="mt-4 w-100">
        <CommonTable
          title={`Asignaciones ${profile?.currentRole?.nameRolEsp || "--"}`}
          headerList={HEADER_OPC_TABLE}
          additionalComponent={
            <FilterTableOwnerAndOPC
              isLoading={loading}
              onSubmit={(filter) => setCurrentFilter(filter)}
            />
          }
          items={itemsLocations}
          actions={commonActions}
          currentPage={paginationControl.currentPage}
          totalPages={paginationControl.totalPages}
          isLoading={loading}
          onHandlePagination={handlePageChange}
        />

        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          size={typeModal === "delete" ? "tiny" : "large"}
        >
          {modalOptions[typeModal]}
        </Modal>
      </Container>
    </ApprovalRejectionProvider>
  );
};

export default OPCSections;
