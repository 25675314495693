import React, { Fragment, useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import ROUTES from "./router/route";
import Layout from "./layouts/layout";
import {
  DashboardScreen,
  LoginScreen,
  NotFoundScreen,
  NotificationsScreen,
  SignUpScreen,
  ReportsScreen,
  SecUsersScreen,
  ApprovalRejectionScreen,
  AllLocationsScreen,
  ModelConnectorsScreen,
  EvolutionFacilitiesScreen,
  CheckStatusApprovalScreen,
  PowerEvolutionScreen,
  ConnectorStatusScreen,
  FolioNonUpdateScreen,
  HistoricalChangesScreen,
  DownloadDataVariableScreen
} from "./pages/";
import RequireAuth from "./components/requireAuth";
import { ToastContainer } from "react-toastify";
import { functionShared } from "./shared/functions";
import AuthProfileScreen from "./pages/authProfile";
import { ROLES_NAME } from "./shared/constants";

const { ADMIN, OWNER, SEC, SEC_ONLY_VIEW,  PSE, OPC, CPO } = ROLES_NAME;

const App: React.FC = () => {
  return (
    <Fragment>
      <Router>
        <TitleUpdater />
        <Routes>
          <Route path={ROUTES.LOGIN} element={<LoginScreen />} />
          <Route path={ROUTES.SIGN_UP} element={<SignUpScreen />} />

          <Route element={<Layout />}>
            <Route element={<RequireAuth allowed={[SEC, SEC_ONLY_VIEW]} />}>
              <Route path="/user-setting" element={<h1>User setting</h1>} />
            </Route>

            <Route
              element={
                <RequireAuth allowed={[OWNER, SEC, SEC_ONLY_VIEW, PSE, ADMIN, OPC, CPO]} />
              }
            >
              <Route
                path={ROUTES.BASE}
                element={<Navigate to={ROUTES.HOME} />}
              />
              <Route
                path={ROUTES.AUTH_PROFILE}
                element={<AuthProfileScreen />}
              />

              <Route path={ROUTES.HOME} element={<DashboardScreen />} />
              <Route
                path={ROUTES.APPROVARLREJECTION}
                element={<ApprovalRejectionScreen />}
              />
              <Route
                path={ROUTES.ALLLOCATIONS}
                element={<AllLocationsScreen />}
              />
              <Route
                path={ROUTES.NOTIFICATIONS}
                element={<NotificationsScreen />}
              />
              <Route
                path={ROUTES.CONNECTORSMODEL}
                element={<ModelConnectorsScreen />}
              />
              <Route element={<RequireAuth allowed={[SEC, SEC_ONLY_VIEW]} />}>
                <Route path={ROUTES.REPORTS} element={<ReportsScreen />} />
                <Route
                  path={ROUTES.FACILITIESEVOUTIONS}
                  element={<EvolutionFacilitiesScreen />}
                />
                <Route
                  path={ROUTES.CHECKSTATUSAPPROVALS}
                  element={<CheckStatusApprovalScreen />}
                />
                <Route
                  path={ROUTES.POWEREVOLUTIONS}
                  element={<PowerEvolutionScreen />}
                />
                <Route
                  path={ROUTES.STATUSCONNECTORS}
                  element={<ConnectorStatusScreen />}
                />
                <Route
                  path={ROUTES.FOLIONONUPDATE}
                  element={<FolioNonUpdateScreen />}
                />
                <Route
                  path={ROUTES.HISTORICALCHANGES}
                  element={<HistoricalChangesScreen />}
                />
              </Route>
              <Route
                path={ROUTES.DOWNLOADDATAVARIABLE}
                element={<DownloadDataVariableScreen />}
              />
            </Route>

            <Route element={<RequireAuth allowed={[ADMIN]} />}>
              <Route path="/sec-users" element={<SecUsersScreen />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFoundScreen />} />
        </Routes>
      </Router>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Fragment>
  );
};

const TitleUpdater = () => {
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname;
    document.title = `SEC - ${functionShared.pathTranslate(path)}`;
  }, [location.pathname]);

  return null;
};

export default App;
