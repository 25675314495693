import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Sidebar, Menu, Icon, Modal, TextArea } from "semantic-ui-react";
import CommonButton from "../../../components/common/commonButton";
import CommonInput from "../../../components/common/commonInput";
import CommonInputFile from "../../../components/common/commonInputFile"; // Importa el componente para cargar archivos
import "../../../container/home/sections/components/styles.scss";
import AuthManager from "../../../shared/functions/AuthManager";
import { useLocation } from "react-router-dom";
import useSessionStore from "../../../store/useSession";
import { toast } from "react-toastify";
import { TextReportProblem } from "../../../container/login/constants/text";
import { getEmailReport, putEmailReport, sendEmailReport } from "../../../fetch/services/reports.services";
import ReportsService from "../../../services/ReportsService";
import useFileUpload from "../../../shared/hooks/useFileUpload"; // Importa el hook de carga de archivos

const reportsService = new ReportsService();

interface MenuItem {
  label: string;
  path?: string;
  roles: string[];
  submenu?: MenuItem[];
}

interface SidebarProps {
  visible: boolean;
  onToggle?: () => void;
  onLogout: () => void;
}

const SidebarComponent: React.FC<SidebarProps> = ({
  visible,
  onToggle,
  onLogout,
}) => {

  const { profile } = useSessionStore();
  const { handleUpload } = useFileUpload(); // Usa el hook para cargar el archivo
  const menuItems: MenuItem[] = AuthManager.getMenuItems(profile?.currentRole);
  const location = useLocation();

  const [expandedMenus, setExpandedMenus] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [errorData, setErrorData] = useState({
    email: false,
    subject: false,
    description: false,
  });
  const [emailReport, setEmailReport] = useState(false);
  const [openModalEditUser, setOpenModalEditUser] = useState(false);
  const [file, setFile] = useState<File | null>(null); // Estado para el archivo adjunto

  const [activeItem, setActiveItem] = useState<string>(location.pathname);
  const [formEditUser, setFormEditUser] = useState({
    email: '',
    subject: '',
    description: '',
  })


  const toggleMenu = (label: string) => {
    if (expandedMenus.includes(label)) {
      setExpandedMenus(expandedMenus.filter(item => item !== label));
    } else {
      setExpandedMenus([...expandedMenus, label]);
    }
  };

  const handleEditUser = ((key: string) => (_value: any) => {
      let value = "";
      if (!!_value) value = _value;
      setFormEditUser((prevState) => ({
        ...prevState,
        [key]: value,
      }));
      if(value.trim()){
        setErrorData((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      }
    }
  );

  useEffect(() => {
    const loadEmail = async () => {
      const email = await getEmailReport();
      if(email){
        setFormEditUser((prevState) => ({
          ...prevState,
          email: email,
        }));
        setEmailReport(true);
      }
    };
    if(!emailReport){
      loadEmail();
    }
  }, [formEditUser, emailReport, profile?.currentRole?.nameRol]);

  const validateFormEditUser = (isEdit: Boolean) => {
    let isValid = true
    if (!formEditUser.email && isEdit) {
      setErrorData((prevState) => ({
        ...prevState,
        email: true,
      }));
      isValid = false;
    }
    if(!isEdit){
      if (!formEditUser.subject) {
        setErrorData((prevState) => ({
          ...prevState,
          subject: true,
        }));
      }
      if (!formEditUser.description) {
        setErrorData((prevState) => ({
          ...prevState,
          description: true,
        }));
      }
      isValid = !formEditUser.subject || !formEditUser.description ? false : true;
    }
    return isValid;
  }

  const handleModalConfig = async() => {
    setFormEditUser({
      email: "",
      subject : "",
      description: ""
    });
    setErrorData({
      subject : false,
      description: false,
      email: false
    });
    setFile(null);
    if(profile?.currentRole?.nameRol === "SEC"){
      setEmailReport(false);
    }
    setOpenModalEditUser(false);
  }

  const handleSubmitEditUser = async (isEdit: Boolean) => {
    try {
      setLoading(true);
      if (validateFormEditUser(isEdit)) {
        if (isEdit) {
          try {
            await putEmailReport({ new_email: formEditUser.email });
            toast.success("Correo de reporte actualizado exitosamente.");

            handleModalConfig();
          } catch (error) {
            toast.error("El correo de reporte no se pudo actualizar");
            handleModalConfig();
          }
        } else {
          let attachmentUrl = null;
  
          // Si hay un archivo, intenta subirlo
          if (file) {
            try {
              await handleUpload(
                file,
                "reports",
                profile?.userId || 0 // Asegúrate de tener el ID de usuario
              );
              // Puedes setear un mensaje o una URL predeterminada si necesitas un valor para `attachmentUrl`
              attachmentUrl = "Archivo subido exitosamente"; // O deja null si no es necesario
            } catch (error) {
              console.error("Error al subir el archivo:", error);
              toast.error("Ocurrió un error al intentar subir el archivo");
            }
          }
  
          await sendEmailReport({
            message: formEditUser.description,
            subject: formEditUser.subject,
            user_email: formEditUser.email,
            user_id: profile?.userId,
            user_name: profile?.name,
            attachment: attachmentUrl || null, // Incluye el mensaje si existe
          });
          
          handleModalConfig();
          toast.success(TextReportProblem.SUCCESS);
        }
      }
    } catch (err) {
      console.log(err);
      handleModalConfig();
      toast.error("Ocurrió un error al enviar el reporte.");
    } finally {
      setLoading(false);
    }
  };


  const renderMenuItem = (menuItem: MenuItem, parentPath?: string): JSX.Element => {
    const itemPath = parentPath ? `${parentPath}/${menuItem.path}` : menuItem.path;
    if (menuItem.submenu) {
      const isExpanded = expandedMenus.includes(menuItem.label);
      return (
        <Menu.Item key={menuItem.label}>
          <Menu.Header onClick={() => toggleMenu(menuItem.label)}>
            {menuItem.label}
            <Icon name={isExpanded ? "angle down" : "angle right"} />
          </Menu.Header>
          <Menu.Menu style={{ display: isExpanded ? "block" : "none" }}>
            {menuItem.submenu.map((subItem) => (
              renderMenuItem(subItem, itemPath)
            ))}
          </Menu.Menu>
        </Menu.Item>
      );
    } else {
      return (
        <Menu.Item
          key={menuItem.label}
          as={menuItem.path ? Link : undefined}
          to={itemPath || undefined}
          active={itemPath === activeItem}
          onClick={() => setActiveItem(itemPath || "")}
          className={itemPath === activeItem ? "active-menu-item" : ""}
        >
          {menuItem.label}
        </Menu.Item>
      );
    }
  };
  const renderMenuItems = (): JSX.Element[] => {
    return menuItems.map((menuItem) => {
      return renderMenuItem(menuItem);
    });
  };

  const contentModalEmail = () => {
    if(profile?.currentRole?.nameRol === "SEC"){
      return (
        <Modal.Content>
          <strong>Todos los reclamos / contactos de clientes serán enviados al correo seleccionado.</strong>
          <div>
            <CommonInput
              label="Email"
              placeholder="Ingrese el email"
              onChange={e => handleEditUser("email")(e.target.value)}
              value={formEditUser.email}
              error={errorData.email}
              errorMessage={"Este campo no puede estar vacío"}
            />
          </div>
          <CommonButton
            className="mt-2 text-center"
            mode="primary"
            label="Guardar"
            onClick={() => handleSubmitEditUser(true)}
            loading={loading}
          />
        </Modal.Content>
      )
    }
    return(
        <Modal.Content>
          <div>
            <CommonInput
              label="Asunto"
              placeholder="Ingrese el asunto"
              onChange={e => handleEditUser("subject")(e.target.value)}
              value={formEditUser.subject}
              error={errorData.subject}
              errorMessage={"Este campo no puede estar vacío"}
            />
            <div className="d-flex flex-column py-1">
              <label className={`common-label-component`}>
                Descripción del problema
              </label>
              <div className={`ui ${errorData.description ? "error" : ""} input`}>
                <TextArea
                  className="description-text-area"
                  placeholder="Escribe el problema..."
                  value={formEditUser.description}
                  onChange={e => handleEditUser("description")(e.target.value)}
                  rows={5}
                />
              </div>
              <div className="common-error-container">
                {errorData.description && <span className="common-error-message">*Este campo no puede estar vacío</span>}
              </div>
            </div>
            <div className="py-2">
          {/* Componente para subir archivos */}
          <CommonInputFile
            label="Adjuntar archivo"
            placeholder="Selecciona un archivo para adjuntar"
            textButton="Seleccionar"
            onLoad={(file) => setFile(file)}
          />
        </div>
          </div>
          <CommonButton
            className="mt-2 text-center"
            mode="primary"
            label="Enviar"
            onClick={() => handleSubmitEditUser(false)}
            loading={loading}
          />
        </Modal.Content>
    )
  }

  const downloadFile = async (link: string) => {
    try {
      const filename = link.substring(link.lastIndexOf("/") + 1).split("?")[0];
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = function () {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(xhr.response as Blob);
        a.download = filename;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };
      xhr.open("GET", link);
      xhr.send();
    } catch (error) {
      console.log(error);
    }
  };
  
  const fetchData = async () => {
    setLoading(true);
    try {
        const body: Record<string, any> = {};
        body.user_id = profile?.userId;
        const responseURL = await reportsService.getLocationsUser(body);

        if (responseURL.errorMessage) {
          toast.error('Fallo al procesar la solicitud de datos del reporte');
          throw new Error('Fallo al procesar la solicitud de datos del reporte');
        };
        downloadFile(responseURL.data);

    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
    <Sidebar
      className="sidebar-container-component"
      as={Menu}
      animation="push"
      icon="labeled"
      inverted
      vertical
      visible={visible}
      width="thin"
    >
      {renderMenuItems()}
      {profile?.currentRole?.nameRol !== "SEC" &&
        <Menu.Item className="item-sidebar-push-down-3" as="a" 
        onClick={() => fetchData()}>
          Descarga de datos
        </Menu.Item>
      }
      <Menu.Item className="item-sidebar-push-down-2" as="a" 
      onClick={() => setOpenModalEditUser(true)}>
        {profile?.currentRole?.nameRol === "SEC" ? "Configuración" : "Contactenos"}
      </Menu.Item>
      <Menu.Item className="item-sidebar-push-down" as="a" onClick={onLogout}>
        Logout
      </Menu.Item>
    </Sidebar>
    <Modal
      open={openModalEditUser}
      onClose={() => handleModalConfig()}
      size={profile?.currentRole?.nameRol === "SEC" ? "mini": "tiny"}
      closeIcon={true}
      dimmer={'blurring'}
    >
      <Modal.Header>{profile?.currentRole?.nameRol === "SEC" ? "Modificar": "Reportar un problema"}</Modal.Header>
      {contentModalEmail()}
    </Modal>
  </div>
  );
};

export default SidebarComponent;
