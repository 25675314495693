import { Container } from "semantic-ui-react";
import { ApprovalRejectionProvider } from "./context";
import RevisionsList from "./sections/revisionsList";

const ModelConnectorsContainer: React.FC<{}> = () => {

  return (
        <ApprovalRejectionProvider>
            <Container className="mt-4 w-100">
              <RevisionsList />
            </Container>
        </ApprovalRejectionProvider>
    );
};

export default ModelConnectorsContainer;

