import React, { ChangeEvent, useRef, useState } from "react";
import "./styles/customInputFile.scss";
import { functionShared } from "../../shared/functions";

interface InCommonInputFile {
  onLoad: (doc: File | null) => void;
  placeholder?: string;
  textButton: string;
  icon?: string;
  label?: string;
  accept?: string;
  error?:boolean
  errorMessage?:string
}

const CommonInputFile: React.FC<InCommonInputFile> = ({
  placeholder,
  label,
  onLoad,
  textButton,
  icon = "paperclip",
  accept = ".pdf, .png, .jpg, .jpeg, .xlsx",
  errorMessage,
  error=false,
}) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [filename, setFilename] = useState("");

  const OnTriggerFile = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;
    onLoad(selectedFile);
    setFilename(selectedFile?.name || "");
  };

  return (
    <div className="d-flex flex-column mb-1">
      {label && (
        <label className="common-label-file-component" htmlFor="inputFile" style={{position: 'relative'}}>
          {label}
        </label>
      )}
      <div className="ui action input">
        <input
          className="common-input-file-component"
          type="text"
          id="inputFile"
          placeholder={placeholder}
          value={filename}
          disabled
        />
        <input
          ref={fileRef}
          type="file"
          accept={accept}
          hidden
          onChange={handleFileChange}
        />
        <button
          onClick={OnTriggerFile}
          className="common-button-file-component ui gray right labeled icon button mobile-only"
        >
          <i className={`${icon} icon`} />
          <span className="button-file-only-computer">{textButton}</span>
          <span className="button-file-only-mobile">
            {functionShared.shortName(filename, 30) || textButton}
          </span>
        </button>
      </div>

      <div className="common-error-container">
        {error && (
          <span className="common-error-message">* {errorMessage}</span>
        )}
      </div>
    </div>
  );
};

export default CommonInputFile;
