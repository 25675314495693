import React, { Fragment, useCallback, useState } from "react";
import {
  Form,
  Input,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "semantic-ui-react";
import {
  CommonButton,
  CommonInput,
  CommonInputFile,
  CommonText,
} from "../../../../../../components";
import { toast } from "react-toastify";
import { InModalAssignment } from "../../../../types";
import {
  handleValidationAssignmentSchema,
  handleValidationCodeSchema,
} from "../../../../schema/homeModalInfo.schema";
import { InGlobalModalOwnerProps } from "../types";
import {
  approvalsService,
  getLocationsService,
} from "../../../../../../fetch/services/locations.services";
import useSessionStore from "../../../../../../store/useSession";
import { functionShared } from "../../../../../../shared/functions";
import useFileUpload from "../../../../../../shared/hooks/useFileUpload";

interface InAssignment {
  locationId: number;
  owner: number | undefined;
  isConfirm: boolean;
}

const initialValues = {
  folioId: "",
  username: "",
  rut: "",
  email: "",
  chargers: "",
  connectors: "",
  address: "",
  commune: "",
  region: "",
  message: "",
  file: null,
};
const AUTO_ASSIGNMENT_KEY_CONSTANT = 4;

const ModalAutoAssignment: React.FC<InGlobalModalOwnerProps> = ({
  onClose,
}) => {
  const { profile } = useSessionStore();
  const { handleUpload } = useFileUpload();
  const [formState, setFormState] = useState<InModalAssignment>(initialValues);
  const [formErrors, setFormErrors] =
    useState<InModalAssignment>(initialValues);
  const [isFetching, setIsFetching] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [secondModal, setSecondModal] = useState(false);

  const [assignmentData, setAssignmentData] = useState<InAssignment>({
    locationId: 0,
    owner: undefined,
    isConfirm: false,
  });

  const handleChangeInput = useCallback(
    (key: keyof InModalAssignment) => (_value: any) => {
      let value;
      if (key === "commune" || key === "region") {
        value = _value?.value;
      } else {
        const { target } = _value;
        value = target.value;
      }
      setFormState({
        ...formState,
        [key]: value,
      });

      setFormErrors((prevValues) => ({ ...prevValues, [key]: "" }));
    },
    [formState]
  );
  const handleFile = (file: File | null) => {
    setFormErrors((prevValues) => ({ ...prevValues, file: null }));
    setFormState({
      ...formState,
      file: file,
    });
  };
  const handleFolioSearch = async () => {
    try {
      const { isValid, errors } = await handleValidationCodeSchema(
        formState.folioId
      );
      if (isValid) {
        await getFolioById();
      } else {
        setFormErrors(errors);
      }
    } catch (error) {}
  };

  const initHandleAssignment = async () => {
    try {
      const { isValid, errors } = await handleValidationAssignmentSchema(
        formState
      );

      if (isValid) {
        await handleAssignment();
      } else {
        setFormErrors(errors);
      }
    } catch (error) {
      toast.error("Ocurrio un error al generar");
    }
  };

  const getFolioById = async () => {
    try {
      setIsFetching(true);
      const location = await getLocationsService({
        folio_irve: formState.folioId,
      });
      if (!location.items[0]) {
        toast.info(
          "No sé encontro información asociada al número de folio ingresado"
        );
        setIsFetching(false);
        setFormState({ ...initialValues, folioId: formState.folioId });
      } else {
        setFormErrors(initialValues);
        const item = location.items[0];

        if (profile && profile.rut === item.owner?.RUT) {
          setIsFetching(false);
          return toast.info("Ya cuentas con el folio asignado");
        }

        let connectors_count = item.evses.reduce(
          (total, evse) => total + evse.connectors.length,
          0
        );
        setFormState({
          ...formState,
          rut: item?.owner?.RUT || "---",
          email: item.owner?.email || "---",
          message: "",
          chargers: item.evses.length.toString(),
          commune: item.commune,
          connectors: connectors_count.toString(),
          region: item.region,
          username: item?.owner?.name || "---",
          address: item.address,
          folioId: formState.folioId,
        });

        setAssignmentData({
          locationId: item.location_id,
          owner: item?.owner?.user_id,
          isConfirm: !!item?.owner?.email,
        });
        setIsFetching(false);
      }
    } catch (error) {
      toast.error("Error al realizar la petición");
      setIsFetching(false);
    }
  };

  const handleAssignment = async () => {
    try {
      if (isSaving || !profile || !assignmentData.locationId) return;
      setIsSaving(true);

      const status = await approvalsService(
        AUTO_ASSIGNMENT_KEY_CONSTANT,
        profile.userId,
        assignmentData.locationId,
        formState.message,
        assignmentData.owner,
        assignmentData.isConfirm
      );
      const approvalId = functionShared.getApprovalInResponse(status);
      if (approvalId && formState.file) {
        await handleUpload(
          //@ts-ignore
          formState.file,
          "locations",
          assignmentData.locationId,
          approvalId
        );
      }
      toast.success("Petición realizada con éxito");
      setSecondModal(true);
      setIsSaving(false);
    } catch (error: any) {
      toast.error(error.message);
      setIsSaving(false);
    }
  };

  const handleSubmit = () => {
    setSecondModal(false);
    onClose();
  };

  return (
    <Fragment>
      {secondModal ? (
        <Fragment>
          <ModalHeader className="text-center">Aviso</ModalHeader>
          <ModalContent className="text-bold mx-3">
            Al autoasignarse como Propietario de una IRVE, usted asume la responsabilidad de actualizar toda la información y cumplir los requerimientos de interoperabilidad indicados en el Reglamento N°12 de 2023 del Ministerio de Energías.
            <br />
            <br /> Una vez sea aprobada la auto designación recibirá una notificación en esta plataforma y al email por usted registrado.
          </ModalContent>
          <ModalActions
            className="text-center mt-2"
            content={<CommonButton label="Aceptar" onClick={handleSubmit} />}
          ></ModalActions>
        </Fragment>
      ) : (
        <Fragment>
          <ModalHeader>Auto-asignaciones de IRVE y Nro Folio</ModalHeader>
          <ModalContent>
            <CommonInput
              value={formState.folioId}
              label="Número de Folio"
              placeholder="Número de Folio"
              action={{
                icon: "search",
                content: "Buscar",
                color: "primary",
                onClick: handleFolioSearch,
                loading: isFetching,
              }}
              error={Boolean(formErrors.folioId)}
              errorMessage={formErrors.folioId}
              onChange={handleChangeInput("folioId")}
            />

            <CommonText mode="h4" className="text-center my-0">
              Datos del propietario actual
            </CommonText>

            <Form.Field className="mb-1">
              <label className="text-bold" style={{ fontSize: "12px" }}>
                Nombre o razón social
              </label>
              <Input
                value={formState.username}
                placeholder="Nombre o razón social"
                error={Boolean(formErrors.username)}
                errorMessage={formErrors.username}
                onChange={handleChangeInput("username")}
                className="w-100"
                disabled
              />
            </Form.Field>

            <Form.Field className="mb-1">
              <label className="text-bold" style={{ fontSize: "12px" }}>
                RUT
              </label>
              <Input
                value={formState.rut}
                error={Boolean(formErrors.rut)}
                errorMessage={formErrors.rut}
                placeholder="RUT"
                onChange={handleChangeInput("rut")}
                className="w-100"
                disabled
              />
            </Form.Field>

            <Form.Field className="mb-1">
              <label className="text-bold" style={{ fontSize: "12px" }}>
                Correo eléctronico
              </label>
              <Input
                value={formState.email}
                placeholder="Correo eléctronico"
                error={Boolean(formErrors.email)}
                errorMessage={formErrors.email}
                onChange={handleChangeInput("email")}
                disabled
                className="w-100"
              />
            </Form.Field>

            <Form.Field className="mb-1">
              <label className="text-bold" style={{ fontSize: "12px" }}>
                Cantidad de cargadores
              </label>
              <Input
                value={formState.chargers}
                placeholder="Cantidad de cargadores"
                error={Boolean(formErrors.chargers)}
                errorMessage={formErrors.chargers}
                onChange={handleChangeInput("chargers")}
                disabled
                type="number"
                className="w-100"
              />
            </Form.Field>

            <Form.Field className="mb-1">
              <label className="text-bold" style={{ fontSize: "12px" }}>
                Dirección de instalación
              </label>
              <Input
                value={formState.address}
                placeholder="Dirección de instalación"
                error={Boolean(formErrors.address)}
                errorMessage={formErrors.address}
                onChange={handleChangeInput("address")}
                disabled
                className="w-100"
              />
            </Form.Field>

            <Form.Field className="mb-1">
              <label className="text-bold" style={{ fontSize: "12px" }}>
                Comuna
              </label>
              <Input
                placeholder="Comuna"
                onChange={handleChangeInput("commune")}
                disabled
                error={Boolean(formErrors.commune)}
                errorMessage={formErrors.commune}
                value={formState.commune}
                className="w-100"
              />
            </Form.Field>

            <Form.Field className="mb-1">
              <label className="text-bold" style={{ fontSize: "12px" }}>
                Región
              </label>
              <Input
                placeholder="Región"
                onChange={handleChangeInput("region")}
                value={formState.region}
                error={Boolean(formErrors.region)}
                errorMessage={formErrors.region}
                disabled
                className="w-100"
              />
            </Form.Field>

            <CommonText mode="h4" className="text-center mt-0">
              Motivo de la Autoasignación
            </CommonText>
            <Form>
              <Form.TextArea
                style={{ resize: "none" }}
                value={formState.message}
                placeholder="Datos adicionales"
                rows={5}
                width="sixteen"
                onChange={handleChangeInput("message")}
                error={!!formErrors.message ? formErrors.message : undefined}
              />
              <CommonInputFile
                label="Adjuntar anexo"
                placeholder="Adjuntar anexo"
                textButton="Seleccionar"
                onLoad={(file) => handleFile(file)}
                error={Boolean(formErrors.file)}
                errorMessage={`${formErrors.file}`}
              />
            </Form>
          </ModalContent>
          <ModalActions className="text-center">
            <CommonButton
              label="Guardar"
              onClick={initHandleAssignment}
              loading={isSaving}
            />
            <CommonButton mode="secondary" label="Cancelar" onClick={onClose} />
          </ModalActions>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ModalAutoAssignment;
