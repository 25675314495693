import { useEffect, useState } from "react";

import { Filters, IPagination } from "../../../shared/types";
import { ModelConnectorsService } from "../../../services/modelConnectorService"
import { toast } from "react-toastify";
import useSessionStore from "../../../store/useSession";


const initialPagination = {
  currentPage: 1,
  totalPages: 1,
};

const modelConnectorService = new ModelConnectorsService();

const useBrandConnectorData = (): any => {
  const { profile } = useSessionStore();
  const [revisions, setRevisions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<IPagination>(initialPagination);
  const [filters, setFilters] = useState<Filters>({});

  useEffect(() => {
    if (profile) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [profile, filters]);

  async function fetchData() {
    setLoading(true);
    if (!filters) return;
    setLoading(true);
    try {
      const querys: Record<string, any> = customFilters();
      const { items, pagination } = await modelConnectorService.getAllBrands(querys);
      setRevisions(items);
      setPagination(pagination);
    } catch (error) {
      toast.error("Error en la búsqueda de marcas.");
    } finally {
      setLoading(false);
    }
  }

  const customFilters = () => {
    const { brand, page = 1 } = filters;
    const params: Record<string, any> = {};

    if (brand) params.brand_name = brand;
    return { ...params, page };
  };

  return {
    revisions,
    loading,
    pagination,
    setFilters,
  };
};
export default useBrandConnectorData;
