export const HEADER_OWNER_TABLE = [
  { key: "folio_IRVE", text: "Folio" },
  { key: "name", text: "Nombre Instalación" },
  { key: "created_at", text: "Fecha Instalación" },
  { key: "address", text: "Dirección" },
  { key: "commune", text: "Comuna" },
  { key: "region", text: "Región" },
  { key: "total_evses", text: "Cant. Cargadores" },
  { key: "total_connectors", text: "Cant. Conectores" },
  { key: "opc", text: "OPC" },
  { key: "pse", text: "PSE" },
  { key: "owner", text: "Propietario" },
  { key: "datos_IRVE_confirmados", text: "Datos confirmados" },
  { key: "last_updated", text: "Actualización" },
  { key: "action", text: "Acciones" },
];
