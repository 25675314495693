import { connectorsAdapter } from "./adapters/connectorAdapter";
import { APIService } from "../shared/utils";
import { Endpoints } from "../shared/constants";

const { MODEL_EVSE, MODEL_BRAND } = Endpoints;

export class ModelConnectorsService extends APIService {
    async getAllEvses(filters?: Record<string, any>): Promise<any> {
        try {
            const response = await this.get(MODEL_EVSE, filters);
            return response
        } catch (error) {
            throw error;
        }
    }

    async createEvse(data?: Record<string, any>): Promise<any> {
        try {
            return await this.post(MODEL_EVSE, data);
        } catch (error) {
            return { errorMessage : error }
        }
    }

    async createBrand(data?: Record<string, any>): Promise<any> {
        try {
            return await this.post(MODEL_BRAND, data);
        } catch (error) {
            return { errorMessage : error }
        }
    }

    async getAllBrands(filters?: Record<string, any>): Promise<any> {
        try {
            const response = await this.get(MODEL_BRAND, filters);
            return connectorsAdapter(response);
        } catch (error) {
            throw error;
        }
    }
}