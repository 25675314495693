// AuthManager.ts

import ROUTES from "../../router/route";
import { ROLES_NAME } from "../constants";

const { SEC, SEC_ONLY_VIEW, PSE, OWNER, ADMIN, OPC, CPO } = ROLES_NAME;
interface MenuItem {
  label: string;
  path?: string;
  roles: string[];
  submenu?: MenuItem[];
}

interface IAuthManager {
  getMenuItems: (currentRol: any) => MenuItem[];
}

const AuthManager: IAuthManager = (() => {
  const menuItems: MenuItem[] = [
    {
      label: "Bandeja de Asignación",
      path: ROUTES.HOME,
      roles: [PSE, OWNER, OPC, CPO],
    },
    {
      label: "Revisión de Solicitudes",
      path: ROUTES.APPROVARLREJECTION,
      roles: [OWNER, SEC, OPC, CPO, PSE],
    },
    {
      label: "Revisión de instalaciones",
      path: ROUTES.ALLLOCATIONS,
      roles: [SEC],
    },
    {
      label: "Notificaciones",
      path: ROUTES.NOTIFICATIONS,
      roles: [],
    },
    {
      label: "Configuración",
      roles: [ADMIN],
      submenu: [
        { label: "Supervisores SEC", path: ROUTES.USERSSEC, roles: [ADMIN] },
      ],
    },
    {
      label: "Modelos de conectores",
      path: ROUTES.CONNECTORSMODEL,
      roles: [SEC, SEC_ONLY_VIEW],
    },
    {
      label: "Reportes",
      roles: [SEC, SEC_ONLY_VIEW],
      submenu: [
  
        {
          label: "Evolución de instalaciones",
          path: ROUTES.FACILITIESEVOUTIONS,
          roles: [SEC, SEC_ONLY_VIEW],
        },
        {
          label: "Aprobaciones Sup. SEC",
          path: ROUTES.CHECKSTATUSAPPROVALS,
          roles: [SEC, SEC_ONLY_VIEW],
        },
        {
          label: "Evolución de potencia",
          path: ROUTES.POWEREVOLUTIONS,
          roles: [SEC, SEC_ONLY_VIEW],
        },
        {
          label: "Estado de conectores",
          path: ROUTES.STATUSCONNECTORS,
          roles: [SEC, SEC_ONLY_VIEW],
        },
        {
          label: "Actualización de datos variables",
          path: ROUTES.FOLIONONUPDATE,
          roles: [SEC, SEC_ONLY_VIEW],
        },
        {
          label: "Histórico de cambios",
          path: ROUTES.HISTORICALCHANGES,
          roles: [SEC, SEC_ONLY_VIEW],
        },
        {
          label: "Descarga de Datos",
          path: ROUTES.DOWNLOADDATAVARIABLE,
          roles: [SEC, SEC_ONLY_VIEW],
        },
      ],
    },
  ];

  const filterMenuItems = (items: MenuItem[], profile: any): MenuItem[] => {
    return items.filter((item) => {
      if (!profile) return false;
      const hasPermission = item.roles.includes(profile.nameRol);
      if (!hasPermission) return false;
      if (item.submenu) {
        item.submenu = filterMenuItems(item.submenu, profile);
        return item.submenu.length > 0;
      }
      return true;
    });
  };

  const getMenuItems = (profile: any) => {
    return filterMenuItems(menuItems, profile);
  };

  return {
    getMenuItems,
  };
})();

export default AuthManager;
