import { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { EnumsService } from '../../services/enumsService';

const enumsService = new EnumsService();

const useEnums = (tableName: string) => {
  const [enums, setEnums] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchEnums = async () => {
      setLoading(true);
      try {
        const enumList = await enumsService.getEnumList(tableName);
        setEnums(enumList);
        setLoading(false);
      } catch (error) {
        toast.error('Error obteniendo los datos.');
        setLoading(false);
      }
    };

    fetchEnums();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableName]);

  return { enums, loading };
};

export default useEnums;
