import React, { Fragment, useState } from "react";
import {
  Form,
  Label,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "semantic-ui-react";
import { CommonButton, CommonInput , CommonInputFile} from "../../../../../../components";
import { InGlobalModalOwnerProps } from "../types";
import { toast } from "react-toastify";
import { InModalAssignment } from "../../../../types";
import { getUserByIDService } from "../../../../../../fetch/services/users.services";
import { approvalsService } from "../../../../../../fetch/services/locations.services";
import { handleValidationTransferSchema } from "../../../../schema/homeModalTransfer.schema";
import useSessionStore from "../../../../../../store/useSession";
import useFileUpload from "../../../../../../shared/hooks/useFileUpload";
import { functionShared } from "../../../../../../shared/functions";

const TRANSFER_KEY_CONSTANT = 2;

const ModalOwnerTransfer: React.FC<InGlobalModalOwnerProps> = ({
  onClose,
  locationId,
  user_id,
}) => {
  const { profile } = useSessionStore();
  const { handleUpload } = useFileUpload(); // Importa el hook para subir el archivo
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [stateForm, setStateForm] = useState<InModalAssignment>(
    {} as InModalAssignment
  );
  const [stateFormError, setStateFormError] = useState<{ message: string }>({
    message: "",
  });

  const searchUser = async () => {
    try {
      setStateFormError({ message: "" });
      setIsFetching(true);
      const users = await getUserByIDService(
        functionShared.normalizeUsername(stateForm.rut)
      );
      if (!users.length) {
        toast.info("No sé encontro información asociada con el RUT");
        setIsFetching(false);
        return;
      }
      const user = users.find(
        (usr) => usr.rut === functionShared.normalizeUsername(stateForm.rut)
      );
      if (!user) {
        toast.info("No sé encontro información asociada con el RUT");
        setIsFetching(false);
        return;
      }
      if (!user.activated_user) {
        toast.info("El usuario no se encuentra activo en la plataforma");
        setIsFetching(false);
        return;
      }
      setStateForm({
        ...stateForm,
        email: user?.email || "---",
        address: user?.direction || "---",
        commune: user?.commune || "---",
        region: user?.region || "---",
        username: user?.name || "---",
        user_target_id: user?.business_details_id,
      });
      setIsEnabled(true);
      setIsFetching(false);
    } catch (error) {
      toast.error("Ocurrio un error al realziar la petición");
    }
  };

  const initHandleTransfer = async () => {
    try {
      const { isValid, errors } = await handleValidationTransferSchema({
        message: stateForm.message,
        file: stateForm.file, 
      });
      if (isValid) {
        await handleTransfer();
      } else {
        setStateFormError({ message: errors.message });
      }
    } catch (error) {
      toast.error("Ocurrió un error al realizar la petición");
    }
  };

  const handleTransfer = async () => {
    try {
      if (!isEnabled || !profile?.userId) return;
      setIsLoading(true);
      if (!profile?.userId) {
        return toast.error(
          "Ocurrió un error al traer la información del usuario"
        );
      }

      const status = await approvalsService(
        TRANSFER_KEY_CONSTANT,
        profile?.userId,
        Number(locationId),
        stateForm.message,
        stateForm.user_target_id
      );

      const approvalId = functionShared.getApprovalInResponse(status);
      if (approvalId && stateForm.file instanceof File) {
        await handleUpload(
          stateForm.file,
          "locations",
          Number(locationId),
          approvalId
        );
      }

      toast.success("Petición realizada correctamente");
      setIsLoading(false);

      onClose();
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  const CustomInputDisabled = (value: string, placeholder: string) => {
    return (
      <input
        value={value}
        placeholder={placeholder}
        className="w-100"
        disabled
        style={{
          opacity: 0.7,
        }}
      />
    );
  };

  return (
    <Fragment>
      <ModalHeader content="Traspaso de propietario" />
      <ModalContent>
        <CommonInput
          onChange={({ target: { value } }) =>
            setStateForm({ ...stateForm, rut: value })
          }
          value={stateForm.rut}
          label="Rut nuevo propietario"
          placeholder="Rut nuevo propietario"
          action={{
            icon: "search",
            content: "Buscar",
            color: "primary",
            onClick: searchUser,
            loading: isFetching,
          }}
        />
        <Form>
          <Label basic className="px-0 mb-0" size="large" color="blue">
            Datos del nuevo propietario
          </Label>

          <Form.Field className="mb-1">
            <label className="text-bold" style={{ fontSize: "12px" }}>
              Nombre o razón social
            </label>
            {CustomInputDisabled(stateForm.username, "Nombre o razón social")}
          </Form.Field>

          <Form.Field className="mb-1">
            <label className="text-bold" style={{ fontSize: "12px" }}>
              RUT
            </label>
            {CustomInputDisabled(stateForm.rut, "RUT")}
          </Form.Field>

          <Form.Field className="mb-1">
            <label className="text-bold" style={{ fontSize: "12px" }}>
              Correo eléctronico
            </label>
            {CustomInputDisabled(stateForm.email, "Correo eléctronico")}
          </Form.Field>

          <Form.Field className="mb-1">
            <label className="text-bold" style={{ fontSize: "12px" }}>
              Dirección
            </label>
            {CustomInputDisabled(stateForm.address, "Dirección")}
          </Form.Field>

          <Form.Field className="mb-1">
            <label className="text-bold" style={{ fontSize: "12px" }}>
              Comuna
            </label>
            {CustomInputDisabled(stateForm.commune, "Comuna")}
          </Form.Field>

          <Form.Field className="mb-1">
            <label className="text-bold" style={{ fontSize: "12px" }}>
              Región
            </label>
            {CustomInputDisabled(stateForm.region, "Región")}
          </Form.Field>

          <Label
            basic
            className="px-0 mb-1 text-center"
            size="large"
            color="blue"
          >
            Motivo del Traspaso
          </Label>
          <Form.TextArea
            label="Motivo del traspaso"
            rows={5}
            placeholder="Motivo de la solicitud de traspaso"
            style={{ width: "100%", resize: "none" }}
            onChange={({ target: { value } }) =>
              setStateForm({ ...stateForm, message: value })
            }
            value={stateForm.message}
            error={
              !!stateFormError.message ? stateFormError.message : undefined
            }
            errorMessage={stateFormError.message}
          />
          {/* Solicitud de archivo adjunto */}
          <CommonInputFile
            placeholder="Adjuntar anexo"
            label="Adjuntar anexo"
            textButton="Seleccionar"
            onLoad={(file) => {
              setStateForm({ ...stateForm, file });
              setStateFormError({ message: "" });
            }}
            errorMessage={stateFormError.message}
            error={Boolean(stateFormError.message)}
          />
        </Form>
      </ModalContent>
      <ModalActions
        content={
          <Fragment>
            <CommonButton
              label="Guardar"
              onClick={initHandleTransfer}
              loading={isLoading}
            />
            <CommonButton label="Cerrar" mode="secondary" onClick={onClose} />
          </Fragment>
        }
        className="text-center"
      />
    </Fragment>
  );
};

export default ModalOwnerTransfer;
