import React, { createContext, ReactNode, useContext, useEffect, useState, useRef } from "react";
import ReportsService from "../../../services/ReportsService";
import { toast } from "react-toastify";

const reportsService = new ReportsService();

const DataVariableContext = createContext<any>({});

const debounce = (func: (...args: any[]) => void, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any[]) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const DataVariableProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [filters, setFilters] = useState<any>({});
  const [users, setUsers] = useState<any[]>([]);

  const fetchUsers = async (searchTerm: string) => {
    try {
      const querys = new URLSearchParams({ 'RUT_LIKE': searchTerm });
      const usersData = await reportsService.getUsers(querys);
      setUsers(usersData);
    } catch (error) {
      toast.error('No se pudieron cargar los filtros para los usuarios.');
    }
  };

  const debouncedFetchUsers = useRef(debounce(fetchUsers, 500)).current;

  useEffect(() => {
    if (Object.keys(filters).length) fetchData();
    // eslint-disable-next-line
  }, [filters]);

  const downloadFile = async (link: string) => {
    try {
      const filename = link.substring(link.lastIndexOf("/") + 1).split("?")[0];
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = function () {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(xhr.response as Blob);
        a.download = filename;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };
      xhr.open("GET", link);
      xhr.send();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      if (Object.keys(filters).length) {
        const {endDate, startDate, type, rut, folio, role} = filters;
        const body: Record<string, any> = {};

        startDate && (body.start_date = startDate);
        endDate && (body.end_date = endDate);
        role && (body.role = role);
        type && (body.key = type);
        rut && (body.id = rut);
        folio && (body.folio_irve = folio);

        const responseURL = await reportsService.getCsvFile(body);

        if (responseURL.errorMessage) {
          toast.error('Fallo al procesar la solicitud de datos del reporte');
          throw new Error('Fallo al procesar la solicitud de datos del reporte');
        };
        if (responseURL.message){
          toast.info("El archivo sera enviado a su correo registrado");
        }else{
          downloadFile(responseURL.data);
        }
      }
      setFilters({});
    } catch (error) {
      setError(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DataVariableContext.Provider value={{ setFilters, loading, users, error, debouncedFetchUsers }}>
      {children}
    </DataVariableContext.Provider>
  );
};

export const useDataVariableContext = () => useContext(DataVariableContext);
