import React, { Fragment, useState } from "react";
import {
  Form,
  Label,
  ModalActions,
  ModalContent,
  ModalHeader,
  Tab,
  TabPane,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import { MOCK_INFO_CHARGERS } from "../../../../homeConstant";
import { CommonButton } from "../../../../../../components";
import Map from "../../maps";
import { InLocationsItems } from "../../../../../../fetch/types/locations.types";
import "../styles.scss";
import moment from "moment";
import { runFormater } from "../../../../../../shared/utils/runService";

interface InLocationsDetails extends InLocationsItems {
  chargers?: number;
  evses_amount?: number;
  connectors?: number;
  connectors_amount?: number;
  opc?: string;
}

const ModalInformation: React.FC<{
  onClose: () => void;
  info: InLocationsDetails;
}> = ({ onClose, info }) => {
  const [tabChargerActive, setTabChargerActive] = useState(0);
  const [activeConnectorTab, setActiveTabConnectorTab] = useState(0);

  const INFO_TAB_CONSTANTS = {
    installation_date: info.revision_date,
    installation_name: info.name,
    address: info.address,
    commune: info.commune,
    pse:
      info?.PSEs && info.PSEs.length === 1
        ? info.PSEs[0].name
        : info.PSEs && info.PSEs?.length > 1
        ? `${info.PSEs.length} Designado(s)`
        : "---",
    chargers_quantity: info.chargers || info?.evses_amount || 0,
    connector_quantity: info.connectors || info?.connectors_amount || 0,
    region: info.region,
    opc: info.OPC?.name || "---",
    chargers: info.evses,
    updated_date: info.last_updated,
    lat: Number(info?.coordinates?.latitude || 0),
    long: Number(info?.coordinates?.longitude || 0),
  };
  const panes = [
    {
      menuItem: "Información General",
      render: () => (
        <TabPane>
          <Form>
            <Form.Group widths={"equal"}>
              <Form.Input
                label="Nombre instalación"
                value={INFO_TAB_CONSTANTS.installation_name}
              ></Form.Input>
              <Form.Input
                label="Método actualización"
                value={info.api_updates_enabled ? "API" : "Manual"}
              ></Form.Input>
            </Form.Group>

            <Form.Group widths={"3"}>
              <Form.Input
                label="Dirección"
                value={INFO_TAB_CONSTANTS.address}
              />
              <Form.Input label="Comuna" value={INFO_TAB_CONSTANTS.commune} />
              <Form.Input label="Región" value={INFO_TAB_CONSTANTS.region} />
            </Form.Group>

            <Form.Group widths={"4"}>
              <Form.Input
                label="Cant. Cargadores"
                value={INFO_TAB_CONSTANTS.chargers_quantity}
              ></Form.Input>
              <Form.Input
                label="Cant. Conectores"
                value={INFO_TAB_CONSTANTS.connector_quantity}
              ></Form.Input>
              <Form.Input
                label="OPC"
                value={INFO_TAB_CONSTANTS.opc}
              ></Form.Input>
              <Form.Input
                label="PSE"
                value={INFO_TAB_CONSTANTS.pse}
              ></Form.Input>
            </Form.Group>

            <Form.TextArea
              label="Indicaciones"
              value={info.directions || "---"}
            ></Form.TextArea>

            <Form.Group className="d-flex flex-column">
              <label
                className="text-bold mb-1 ml-1"
                style={{ fontSize: "12px" }}
              >
                Ubicación
              </label>
              {/* <Form.Input value={"-36.7966143, -73.0719793"}></Form.Input> */}
              <Map
                latitude={INFO_TAB_CONSTANTS.lat}
                longitude={INFO_TAB_CONSTANTS.long}
                name={INFO_TAB_CONSTANTS.installation_name}
              />
            </Form.Group>
            <span className="text-bold">
              Fecha instalación:
              <span className="ml-1">
                {INFO_TAB_CONSTANTS.installation_date}
              </span>
            </span>
          </Form>
        </TabPane>
      ),
    },
    {
      menuItem: "Datos de Cargadores",
      render: () => (
        <TabPane>
          <Form className="form-tab-content">
            {INFO_TAB_CONSTANTS.chargers.length < 1 && (
              <Label basic className="px-0 mb-1" size="large" color="blue">
                No Existen Cargadores
              </Label>
            )}

            <Tab
              panes={getChargers()}
              onTabChange={(_, data) => {
                handleResetTabs(Number(data?.activeIndex));
              }}
            />

            <Label basic className="px-0 mb-1" size="large" color="blue">
              {INFO_TAB_CONSTANTS.chargers.length > 0
                ? "Datos de Conectores"
                : "No Existen Conectores"}
            </Label>

            <Tab panes={getConnector()} 
                  activeIndex={activeConnectorTab}
                  onTabChange={(_, data) =>
                    handleTabChange(Number(data?.activeIndex || 0))
                  }/>
          </Form>
        </TabPane>
      ),
    },
  ];

  const handleTabChange = (activeIndex: number) => {
    setActiveTabConnectorTab(activeIndex);
  };

  const handleResetTabs = (data: number) => {
    setTabChargerActive(data || 0)
    setActiveTabConnectorTab(0);
  }

  const getChargers = () => {
    if (INFO_TAB_CONSTANTS.chargers.length < 1) return [];
    const sortConnectors = INFO_TAB_CONSTANTS.chargers.sort((a, b) =>
      a.order_number > b.order_number ? 1 : -1
    );
    return sortConnectors.map((item, index) => {
      return {
        menuItem: `Cargador ${index + 1} (${item.evse_uid})`,
        render: () => (
          <TabPane className="px-0" style={{ border: "none" }}>
            <Form.Group>
              <Form.Input
                className="w-100"
                label="Orden Cargador"
                value={item.order_number}
              ></Form.Input>
            </Form.Group>

            <Form.Group widths={"2"}>
              <Form.Input label="Marca" value={item.brand}></Form.Input>
              <Form.Input label="Modelo" value={item.model}></Form.Input>
            </Form.Group>
            <Form.Group widths={"2"}>
              <Form.Input label="Estado" value={item.status}></Form.Input>

              <Form.Input
                label="Potencia máxima"
                value={item.max_electric_power}
              ></Form.Input>
            </Form.Group>

            <Form.Group widths={"equal"} inline>
              <Form.Input
                label="Piso"
                value={item.floor_level ? item.floor_level : "No definido"}
              ></Form.Input>

              <Form.Checkbox
                label="Carga simultanea"
                checked={item.permite_carga_simultanea}
              ></Form.Checkbox>
            </Form.Group>

            <Form.TextArea
              label="Indicaciones para el cargador"
              value={item.directions || "---"}
            ></Form.TextArea>
            <span className="text-bold">
              Última actualización:
              <span className="ml-1">
                {moment(item.last_updated).format("DD MMMM YYYY - HH:mm")}
              </span>
            </span>
          </TabPane>
        ),
      };
    });
  };

  const getInfoPSE = (t?: any) => {
    if (!t.pse_id) return "No se pudo cargar la información del PSE";
    const user = info.PSEs?.find((item) => item.user_id === t.pse_id);
    if (user) {
      return `Tarifa Cargada (id tarifa ${t.tariff_id}) por:    ${user.name} (${runFormater(
        user.RUT
      )})`;
    }
    return "No se pudo cargar la información del PSE";
  };

  const getConnector = () => {
    const ACTIVE_CHARGER = INFO_TAB_CONSTANTS.chargers[tabChargerActive];
    if (!ACTIVE_CHARGER) return [];
    return ACTIVE_CHARGER.connectors.map((item, index) => {
      return {
        menuItem: `Conector ${index + 1} (${item.connector_id})`,
        render: () => (
          <TabPane className="px-0" style={{ border: "none" }}>
            <Form.Group widths={"3"}>
              <Form.Input label="Estado" value={item.status}></Form.Input>
              <Form.Input label="SOC" value={item.soc || "---"}></Form.Input>
              <Form.Input
                label="Tipo Conector"
                // value={item.standard}
                value={
                  MOCK_INFO_CHARGERS.standard.find(
                    (standard) => standard.value === item.standard
                  )?.text || item.standard
                }
              ></Form.Input>
            </Form.Group>

            <Form.Group widths={"3"}>
              <Form.Input
                label="Voltaje máximo"
                value={item.max_voltage}
              ></Form.Input>
              <Form.Input
                label="Amperaje máximo "
                value={item.max_amperage}
              ></Form.Input>

              <Form.Input
                label="Potencia máxima"
                value={item.max_electric_power}
              ></Form.Input>
            </Form.Group>
            <div className="d-flex flex-column">
              <Label className="px-0" basic size="large" color="blue">
                Datos de tarifas
              </Label>

              {item.tariffs.length > 0 ? (
                item.tariffs.map((t) => (
                  <Fragment key={t.tariff_id}>
                    <Label>{getInfoPSE(t)} </Label>
                    <Form.Group widths={"2"}>
                      <Form.Input
                        label="Precio mínimo"
                        value={t.min_price}
                      ></Form.Input>
                      <Form.Input
                        label="Precio  máximo "
                        value={t.max_price}
                      ></Form.Input>
                    </Form.Group>
                    <Label basic size="medium" color="black">
                      Componentes
                    </Label>
                    <Form.Group>
                      {t.elements?.price_components &&
                      t.elements.price_components.length > 0 ? (
                        <Table basic collapsing celled className="ml-2 mb-2">
                          <TableHeader>
                            <TableRow>
                              <TableHeaderCell>Dimensión</TableHeaderCell>
                              <TableHeaderCell>Precio</TableHeaderCell>
                              {/* <TableHeaderCell>Unidad de Cobro</TableHeaderCell> */}
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            {t.elements.price_components.length > 0 ? (
                              t.elements.price_components.map((components) => (
                                <TableRow key={components.order_number}>
                                  <TableCell>
                                    {components.tariff_dimension}
                                  </TableCell>
                                  <TableCell>{components.price}</TableCell>
                                  {/* <TableCell>{components.step_size}</TableCell> */}
                                </TableRow>
                              ))
                            ) : (
                              <TableRow key={Date.now()}>
                                <TableCell className="text-bold" colspan="3">
                                  No existen datos en Dimensions
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      ) : (
                        <Label
                          basic
                          className="mb-1 text-center"
                          size="large"
                          color="black"
                        >
                          No Existen Componentes a Mostrar
                        </Label>
                      )}
                    </Form.Group>
                  </Fragment>
                ))
              ) : (
                <Label
                  basic
                  className="px-0 mb-1 text-center"
                  size="large"
                  color="black"
                >
                  No Existen Tarifas a Mostrar
                </Label>
              )}

              <span className="text-bold">
                Última actualización:
                <span className="ml-1">
                  {moment(item.last_updated).format("DD MMMM YYYY - HH:mm")}
                </span>
              </span>
            </div>
          </TabPane>
        ),
      };
    });
  };
  return (
    <Fragment>
      <ModalHeader content={`Datos del folio ${info.folio_IRVE}`} />
      <ModalContent content={<Tab panes={panes} />} />
      <ModalActions
        content={<CommonButton label="Cerrar" onClick={onClose} />}
        className="text-center"
      />
    </Fragment>
  );
};

export default ModalInformation;
