enum TextConstantLogin {
  LOGIN_HEADER = "Iniciar Sesión",
  NOT_REGISTER= "¿No se encuentra registrado?",
  FORGOT_PASSWORD= "¿Olvidó la contraseña?",
  LOGIN_AUTH_ERROR= 'Datos incorrectos.\nVerifica tu nombre de usuario y contraseña e intenta nuevamente. Gracias.'

}

enum TextEditUser {
  EMPTY_DATA = "Debe modificar al menos un valor.",
  DATA_INVALID = "Hay un error en los datos, favor modificar e intentar nuevamente.",
  REGION_PARTIAL = "Si modifica la región, igualmente debe cambiar comuna y dirección.",
  COMUNE_PARTIAL = "Si modifica la comuna, igualmente debe cambiar la dirección.",
  SUCCESS = "Su información se actualizó correctamente, se recomienda reingresar a la aplicación."
}

enum TextReportProblem {
  SUCCESS = "Se envio el correo correctamente",
}

enum TextErrorLogin {
  LOGIN = "Iniciar Sesión",
}



export {TextConstantLogin, TextErrorLogin, TextEditUser, TextReportProblem}