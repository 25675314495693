import React, { Fragment, useState, SyntheticEvent } from "react";
import {
  Table,
  Dropdown,
  Icon,
  IconProps,
  Grid,
  TableRow,
  TableHeaderCell,
  TableFooter,
  Pagination,
  Popup,
  DropdownProps,
} from "semantic-ui-react";
import CommonText from "./commonText";
import "./styles/customTable.scss";
import CommonDropdown from "./commonDropdown";
import CommonModal from "./commonModal";
import CommonButton from "./commonButton";
import { NUMBER_ITEMS_PAGE } from "../../container/home/homeConstant";

export interface Action {
  icon: IconProps["name"];
  handler: (item?: any) => void;
  hideIcon?: (item?: any) => boolean;
  hint?: string;
}

interface InTableHeader {
  key: string;
  text: string;
}

interface InTableFilterOptions extends InTableHeader {
  options: any[];
}
interface CommonTableProps {
  title?: string;
  headerList: InTableHeader[];
  items: any[];
  filterOptions?: InTableFilterOptions[];
  actions?: Action[];
  currentPage?: number;
  totalPages?: number;
  onHandlePagination?: (event: SyntheticEvent, { activePage }: any) => void;
  onHandleFilter?: (filters: any) => void;
  isLoading?: boolean;
  additionalComponent?: React.ReactNode;
  showPagination?: boolean;
  onInteraction?: (interactionData: any) => void; 
}

const CommonTable: React.FC<CommonTableProps> = ({
  title,
  headerList,
  items,
  filterOptions,
  actions,
  onHandlePagination,
  onHandleFilter,
  totalPages = 1,
  isLoading = false,
  additionalComponent,
  showPagination = true,
  onInteraction,
}) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<
    "ascending" | "descending" | undefined
  >(undefined);

  const [currentFilter, setCurrentFilter] = useState<any>({});
  const [unsavedFilter, setUnsavedFilter] = useState<any>({});
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const handleFilterChange = (key: string, data: any) =>
    setUnsavedFilter({ ...unsavedFilter, [key]: data?.value });

  const handleSort = (clickedColumn: string) => () => {
    if (sortColumn !== clickedColumn) {
      if (onInteraction) {
        onInteraction({ 
          rule: "asc", 
          parameter: clickedColumn 
        });
      }
      setSortColumn(clickedColumn);
      setSortDirection("ascending");
      return;
    }
    if (onInteraction) {
      onInteraction({ 
        rule: sortDirection === "ascending" ? "desc" : "asc", 
        parameter: clickedColumn 
      });
    }
    setSortDirection(
      sortDirection === "ascending" ? "descending" : "ascending"
    );
  };

  const handleItemsPerPageChange = (
    event: React.SyntheticEvent<HTMLElement>, 
    data: DropdownProps
  ) => {
    setItemsPerPage(data.value as number);
    if(onInteraction){
      onInteraction({ numberItems: data.value });
    }
  };

  const addPageCount = () => {
    if(onInteraction) {
      return (
        <Dropdown
          placeholder="Items por página"
          selection
          options={NUMBER_ITEMS_PAGE}
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          className="ml-2 mini"
        />
      )
    }
    return (<></>)
  }
  const sortedData = sortColumn
    ? [...items].sort((a, b) => {
        const direction = sortDirection === "ascending" ? 1 : -1;
        return a[sortColumn] > b[sortColumn] ? direction : -direction;
      })
    : items;

  const saveFilter = () => {
    const _unsavedFilter = Object.fromEntries(
      Object.entries(unsavedFilter).filter(([clave, valor]) => valor)
    );

    setCurrentFilter(_unsavedFilter);
    setOpenFilter(false);

    onHandleFilter && onHandleFilter(_unsavedFilter);
  };

  const removeFilter = () => {
    if (Object.entries(currentFilter).length < 1) {
      setOpenFilter(false);
      return;
    }
    setCurrentFilter({});
    setUnsavedFilter({});
    onHandleFilter && onHandleFilter({});
    setOpenFilter(false);
  };

  const resetModal = () => {
    setUnsavedFilter(currentFilter);
    setOpenFilter(false);
  };

  const getTextFilter = () =>
    !openFilter && Object.values(currentFilter).length > 0
      ? `Filtrar (${Object.values(currentFilter).length})`
      : " Filtrar";

  return (
    <Grid tablet={6} computer={16}>
      <Grid.Row className="d-flex justify-between">
        <Grid.Column
          mobile={16}
          tablet={6}
          computer={16}
          verticalAlign="middle"
        >
          <CommonText mode="h3"> {title} </CommonText>
        </Grid.Column>
        {filterOptions ? (
          <Grid.Column computer={6} textAlign="right" verticalAlign="middle">
            <Dropdown
              text={getTextFilter()}
              icon="filter"
              floating
              labeled
              button
              className="icon hidden-mobile"
              onClick={() => setOpenFilter(!openFilter)}
            />
          </Grid.Column>
        ) : null}
      </Grid.Row>
      {additionalComponent && (
        <Grid.Column
          computer={16}
          tablet={16}
          textAlign="center"
          verticalAlign="middle"
        >
          {additionalComponent}
        </Grid.Column>
      )}
      <Grid.Row style={{ overflowX: "auto" }}>
        <Table unstackable className="px-0" celled padded sortable>
          <Table.Header>
            <Table.Row>
              {headerList.map(({ key, text }, index) => (
                <Table.HeaderCell
                  className="common-table-header-color"
                  key={`${key}-${index}`}
                  onClick={handleSort(key)}
                  sorted={sortColumn === key ? sortDirection : undefined}
                >
                  {text}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {isLoading ? (
              <Table.Row>
                <Table.Cell colSpan={headerList.length} textAlign="center">
                  <Icon name="spinner" size="big" loading />
                </Table.Cell>
              </Table.Row>
            ) : sortedData.length === 0 ? (
              <Table.Row>
                <Table.Cell colSpan={headerList.length} textAlign="center">
                  <CommonText mode="h4">
                    <Icon name="info circle" size="big" />
                    No hay datos disponibles
                  </CommonText>
                </Table.Cell>
              </Table.Row>
            ) : (
              sortedData.map((item, index) => (
                <Table.Row key={index}>
                  {headerList.map(({ key }, index) => (
                    <Table.Cell key={`${key}-${index}`}>
                      {key === "action" ? (
                        <div className="d-flex">
                          {actions &&
                            actions.map((action: Action, colIndex: number) => (
                              <Fragment key={colIndex}>
                                {(!action.hideIcon ||
                                  !action.hideIcon(item)) && (
                                  <Popup
                                    size="mini"
                                    content={action.hint}
                                    trigger={
                                      <Icon
                                        link
                                        name={action.icon}
                                        onClick={() => action.handler(item)}
                                        className={action.icon?.includes("warning sign") ? "common-table-warning-color": "common-table-icon-color"}
                                      />
                                    }
                                  />
                                )}
                              </Fragment>
                            ))}
                        </div>
                      ) : (
                        item[key]
                      )}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))
            )}
          </Table.Body>
          {showPagination && (
            <TableFooter>
              <TableRow textAlign="right">
                <TableHeaderCell colSpan={`${headerList.length}`}>
                  <div className="common-table-pagination-default">
                    <Pagination
                      disabled={isLoading}
                      defaultActivePage={1}
                      ellipsisItem={{
                        content: <Icon name="ellipsis horizontal" />,
                        icon: true,
                      }}
                      firstItem={{
                        content: <Icon name="angle double left" />,
                        icon: true,
                      }}
                      lastItem={{
                        content: <Icon name="angle double right" />,
                        icon: true,
                      }}
                      prevItem={{
                        content: <Icon name="angle left" />,
                        icon: true,
                      }}
                      nextItem={{
                        content: <Icon name="angle right" />,
                        icon: true,
                      }}
                      totalPages={totalPages}
                      onPageChange={onHandlePagination}
                    />
                    {addPageCount()}
                  </div>
                </TableHeaderCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </Grid.Row>
      <CommonModal
        title="Filtrar por"
        size="tiny"
        isOpen={openFilter}
        onClose={resetModal}
        content={
          <Fragment>
            {filterOptions &&
              filterOptions.map(({ text, key, options }) => (
                <CommonDropdown
                  key={key}
                  label={text}
                  value={unsavedFilter[key]}
                  options={options}
                  onChange={(data) => handleFilterChange(key, data)}
                />
              ))}

            <div className="d-flex justify-center">
              <CommonButton
                className="m-auto"
                label="Aplicar filtro"
                onClick={saveFilter}
              />

              <CommonButton
                className="m-auto"
                label={
                  Object.entries(currentFilter).length > 0
                    ? "Quitar filtros"
                    : "Cerrar"
                }
                mode="secondary"
                onClick={removeFilter}
              />
            </div>
          </Fragment>
        }
      />
    </Grid>
  );
};

export default CommonTable;
