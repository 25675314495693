import React, { Fragment, useState } from "react";
import { ModalActions, ModalContent } from "semantic-ui-react";
import { CommonButton, CommonText } from "../../../../../../components";
import { InGlobalModalOwnerProps } from "../types";
import { toast } from "react-toastify";
import { approvalsService } from "../../../../../../fetch/services/locations.services";
import useSessionStore from "../../../../../../store/useSession";

const DELETE_ASSIGNMENT_KEY_CONSTANT = 7;

const ModalDelete: React.FC<InGlobalModalOwnerProps> = ({
  onSuccess,
  onClose,
  locationId,
}) => {
  const { profile } = useSessionStore();
  const [isLoading, setIsLoading] = useState(false);

  const initHandleDelete = async () => {
    try {
      console.log("initHandleDelete ejecutado");
      await handleDelete();
    } catch (error) {
      toast.error("Ocurrio un error al realizar la petición");
    }
  };

  const handleDelete = async () => {
    console.log("handleDelete fue llamado");
    try {
      if (isLoading || !profile) return;
      setIsLoading(true);
      console.log("Payload enviado a approvalsService:", {
        assignmentKey: DELETE_ASSIGNMENT_KEY_CONSTANT,
        userId: profile?.userId || 0,
        locationId: Number(locationId),
        message: "", // Puedes ajustar este mensaje si es necesario
      });
      const message = `${profile?.currentRole?.roleId}`;
      const status = await approvalsService(
        DELETE_ASSIGNMENT_KEY_CONSTANT,
        profile?.userId || 0,
        Number(locationId),
        message, // Puedes ajustar este mensaje si es necesario
      );
      console.log("Respuesta de approvalsService:", status);
      onSuccess();
      toast.success("Acción realizada con éxito");
      setIsLoading(false);
      onClose();
    } catch (error: any) {
      toast.error(error.message);
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <ModalContent>
        <CommonText mode="h3" className="text-center">
          Solicitud Desasignación
        </CommonText>

        <p className="text-center" style={{ fontSize: "14px" }}>
          ¿Está seguro que desea desasignarse de este folio? Puede volver a ser asignado por un propietario si es necesario.
        </p>
      </ModalContent>

      <ModalActions
        className="text-center"
        content={
          <Fragment>
            <CommonButton
              label="Confirmar"
              onClick={initHandleDelete}
              loading={isLoading}
            />
            <CommonButton mode="secondary" label="Cancelar" onClick={onClose} />
          </Fragment>
        }
      />
    </Fragment>
  );
};

export default ModalDelete;
