import { getDataTable, headerLabels, tableActions } from "../../content";
import { useApprovalRejectionContext } from "../../context";
import { CommonModal, CommonTable } from "../../../../components";
import useModal from "../../../../shared/hooks/useModal";
import { Filters } from "../../../../shared/types";
import FilterSection from "../filters";

const RevisionsList = () => {
  const [modalTitle, modalContent, isModalOpen, openModal, closeModal] = useModal();

  const { revisions, loading, pagination, setFilters } =
    useApprovalRejectionContext();

    const handlePageChange = (
      event: React.SyntheticEvent,
      { activePage }: any
    ) => {
      setFilters((prevFilters: Filters) => ({
        ...prevFilters,
        page: activePage,
      }));
    };

  return (
    <div>
      <CommonTable
        title="Modelos de conectores"
        headerList={headerLabels}
        items={getDataTable(revisions)}
        actions={tableActions({ openModal, closeModal })}
        isLoading={loading}
        totalPages={pagination.totalPages}
        onHandlePagination={handlePageChange}
        additionalComponent={<FilterSection />}
      />
      <CommonModal
        isOpen={isModalOpen}
        content={modalContent}
        onClose={closeModal}
        title={modalTitle}
      />
    </div>
  );
};

export default RevisionsList;