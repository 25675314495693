import {
    ApprovalsAndStatusesAdapter,
    communesEnumAdapter,
    facilitiesEvolutionAdapter,
    powerEvolutionAdapter,
    regionsEnumAdapter,
    statusConnectorsAdapter,
    statusTypeApprovalAdapter,
    usersEnumAdapter,
} from "./adapters/reportsAdapter";
import {APIService} from "../shared/utils";
import {Endpoints, TableNames} from "../shared/constants";

const { ENUM, PANEL_INFO, FOLIO_NON_UPDATE, HISTORICAL_CHANGES, USERS, CSV_FILE, LOCATIONS_USER } = Endpoints;
const {
    FACILITIES_EVOLUTION,
    POWER_EVOLUTION,
    STATUS_TYPE_APPROVAL,
    STATUS_CONNECTORS
} = TableNames;

class ReportsService extends APIService {

    async getEvolutionsFacilities(filters?: Record<string, any>): Promise<any> {
        try {
            const querys = new URLSearchParams({ table_name:  FACILITIES_EVOLUTION, ...filters });
            const response = await this.get(PANEL_INFO, querys);
            return facilitiesEvolutionAdapter(response);
        } catch (error) {
            console.error('Error fetching Evolutions Facilities:', error);
            return [];
        }
    }

    async getStatusApprovals(filters?: Record<string, any>): Promise<any> {
        const querys = new URLSearchParams({ table_name:  STATUS_TYPE_APPROVAL, ...filters });
        try {
            const response = await this.get(PANEL_INFO, querys);
            return statusTypeApprovalAdapter(response);
        } catch (error) {
            console.error('Error fetching Status approval:', error);
            throw error;
        }
    }

    async getPowerEvolutions(filters?: Record<string, any>): Promise<any> {
        try {
            const querys = new URLSearchParams({ table_name:  POWER_EVOLUTION, ...filters });
            const response = await this.get(PANEL_INFO, querys);
            return powerEvolutionAdapter(response);
        } catch (error) {
            console.error('Error fetching Power evolutions:', error);
            throw error;
        }
    }

    async getConnectorStatus(filters?: Record<string, any>): Promise<any> {
        try {
            const querys = new URLSearchParams({ table_name:  STATUS_CONNECTORS, ...filters });
            const response = await this.get(PANEL_INFO, querys);
            return statusConnectorsAdapter(response);
        } catch (error) {
            console.error('Error fetching Power evolutions:', error);
            throw error;
        }
    }

    async getListNonUpdate(filters?: Record<string, any>): Promise<any> {
        try {
            const response = await this.get(FOLIO_NON_UPDATE, filters);
            return response.Data;
        } catch (error) {
            console.error('Error fetching Power evolutions:', error);
            throw error;
        }
    }

    async getHistoricalChanges(filters?: Record<string, any>): Promise<any> {
        try {
            const response = await this.get(HISTORICAL_CHANGES, {...filters, report: 'combined'});
            return ApprovalsAndStatusesAdapter(response);
        } catch (error) {
            console.error('Error fetching historical changes:', error);
            throw error;
        }
    }

    async getCsvFile(data?: Record<string, any>): Promise<any> {
        try {
            return await this.post(CSV_FILE, data);
        } catch (error) {
            return { errorMessage : error }
        }
    }

    async getLocationsUser(data?: Record<string, any>): Promise<any> {
        try {
            return await this.post(LOCATIONS_USER, data);
        } catch (error) {
            return { errorMessage : error }
        }
    }

    async getRegions(): Promise<any> {
        try {
            const querys = new URLSearchParams({ table_name: 'regions' || '' });
            const response = await this.get(ENUM, querys);
            return regionsEnumAdapter(response.data);
        } catch (error) {
            console.error('Error fetching regions:', error);
            throw error;
        }
    }

    async getCommunes(): Promise<any> {
        try {
            const querys = new URLSearchParams({ table_name: 'communes' || '' });

            const response = await this.get(ENUM, querys);
            return communesEnumAdapter(response.data);
        } catch (error) {
            console.error('Error fetching coommunes:', error);
            throw error;
        }
    }
    async getUsers(filters?: string | any): Promise<any> {
        try {

            const response = await this.get(USERS, filters);
            return usersEnumAdapter(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
            throw error;
        }
    }
}

export default ReportsService;
