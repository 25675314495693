import React from "react";
import OwnerSection from "./sections/owner.sections";
import { ApprovalRejectionProvider } from "../approvalRejection/context";

const AllLocationsContainer: React.FC<{}> = () => {
  const getRenderScreen = () => {
    return (
      <ApprovalRejectionProvider>
        <OwnerSection />
      </ApprovalRejectionProvider>
    );
  };
  return getRenderScreen();
};

export default AllLocationsContainer;
